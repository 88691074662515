<template>
<v-container fluid>
    <v-row align="center">

        <v-col cols="12" sm="8" md="8">
            <v-card class="elevation-1">
                <v-toolbar color="grey darken-1" flat dense>
                    <v-card-title class="text-body-1">Aggregated Billings</v-card-title>
                </v-toolbar>
                <v-card-text>
                    <v-form>

                        <v-row align="center">

                            <v-col cols="12">
                                <v-select @change="getSelectedValue" v-model="Company" :items="CompanyList" item-value="pkn_id" item-text="name" outlined dense label="Company"></v-select>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <div>

                                            <v-md-date-range-picker @change="getSelectedDateRange" v-bind="attrs" v-on="on"></v-md-date-range-picker>

                                        </div>

                                    </template>

                                </v-menu>
                            </v-col>
                        </v-row>

                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" @click="fetchResults()">SHOW</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>

    <v-row align="center">
        <v-col cols="12" sm="12" md="12">
            <v-toolbar color="grey darken-1 elevation-1" flat>
                <v-toolbar-title class="text-body-1"> Billings List</v-toolbar-title>

                <v-spacer></v-spacer>
                <v-btn v-show="export_json_show" class="mb-2">
                    <vue-excel-xlsx :data="FBillingResults" :columns="export_json_fields" :sheetname="export_json_worksheet" :filename="export_json_filename">
                        EXPORT
                    </vue-excel-xlsx>
                </v-btn>
            </v-toolbar>

            <v-data-table :headers="headers" :items="FBillingResults" class="elevation-1 results" :loading="loading" loading-text="Loading... Please wait" hide-default-footer disable-pagination>

                <template v-slot:items="props">
                    <td class="text-xs-right">{{ props.item.ShortCode }}</td>
                    <td class="text-xs-right">{{ props.item.MNO }}</td>
                    <td class="text-xs-right">{{ props.item.SumBillings }}</td>
                    <td class="text-xs-right">{{ props.item.SumUserSpent }}</td>
                </template>

                <template v-slot:item.SumUserSpent="{ item }">
                    <span>{{ item.SumUserSpent.toLocaleString("el-GR") }} &euro; </span>
                </template>

                <template slot="body.append">
                    <tr class="black--text">
                        <th class="bold-footer">Totals</th>
                        <th class="bold-column"></th>
                        <th class="bold-column">{{ sumField('SumBillings') }}</th>
                        <th class="bold-column">{{ sumField('SumUserSpent').toLocaleString("el-GR") }} &euro;</th>
                    </tr>
                </template>

            </v-data-table>
        </v-col>
    </v-row>

</v-container>
</template>

<script>
import Vue from 'vue';
import VMdDateRangePicker from "v-md-date-range-picker";
import moment from 'moment';
Vue.use(VMdDateRangePicker);

import {
    mapActions,
    mapMutations,
    mapGetters

} from 'vuex'

export default {
    data() {
        return {

            export_json_fields: [
                {
                    label: 'ShortCode',
                    field: 'ShortCode'
                },
                {
                    label: 'MNO',
                    field: 'MNO'
                },
                {
                    label: '# Billings',
                    field: 'SumBillings'
                },
                {
                    label: '(€) User Spent',
                    field: 'SumUserSpent'
                }
            ],

            export_json_worksheet: "export",
            export_json_filename: "export.xlsx",
            export_json_show: false,

            headers: [{
                    text: 'ShortCode',
                    align: 'left',
                    sortable: false,
                    value: 'ShortCode'
                },
                {
                    text: 'MNO',
                    align: 'left',
                    sortable: false,
                    value: 'MNO'
                },
                {
                    text: '# Billings',
                    align: 'left',
                    sortable: false,
                    value: 'SumBillings'
                },
                {
                    text: '(€) User Spent',
                    align: 'left',
                    sortable: false,
                    value: 'SumUserSpent'
                },
            ],
            BillingResults: [],
            FBillingResults: [],
            Company: null,
            CompanyItem: {
                pkn_id: null,
                name: null
            },
            CompanyList: [],
            value: null,
            date: "",
            dates: [moment().format('YYYY-MM-DD')],
            dateFrom: "",
            dateTo: "",
            payload: {},
            loading: false,
            menu: false
        }
    },
    methods: {

        sumField(key) {
            let total = 0
            const sum = this.FBillingResults.reduce((acc, cur) => {
                return (total += +cur[key])
            }, 0)
            return sum
        },

        logout() {
            this.setAuthenticated(false)
            this.updateField('', 'token')
            this.updateField('', 'tokentimestamp')
            this.updateField('', 'username')
            this.updateField('', 'password')
        },

        getSelectedValue(pkn_id) {
            this.Company = pkn_id
        },

        getSelectedDateRange(values) {

            this.setDateFrom(moment(values[0], 'YYYY-MM-DDT21:00:00Z').add(-3, 'h').format('YYYY-MM-DDT21:00:00Z'))
            this.setDateTo(moment(values[1], 'YYYY-MM-DDT20:59:59Z').format('YYYY-MM-DDT20:59:59Z'))

        },

        fetchResults() {

            console.log('Fetching')

            this.BillingResults = []
            this.export_json_show = false
            this.loading = true

            this.setCompanyID(isNaN(parseInt(this.Company)) ? null : parseInt(this.Company))

            this.AggregatedBillingsResults().then((response) => {

                this.BillingResults = response
                this.FBillingResults = []

                let i = 0;
                while (i < this.BillingResults.length) {
                    if (this.BillingResults[i].ShortCode != '54196' && this.BillingResults[i].ShortCode != '54988') {
                        this.FBillingResults.push(this.BillingResults[i])
                    }
                    ++i;
                }

                this.export_json_worksheet = "AggregatedBillings"
                this.export_json_filename = "mobivas_export.xlsx"

                this.export_json_show = true
                this.loading = false
            })
        },
        ...mapGetters({

            usr:"auth/getUser"

        }),
        ...mapActions({
            CompanyListResults: "mobivas/CompanyListResults",
            AggregatedBillingsResults: "mobivas/AggregatedBillingsResults",
            checkToken: "auth/checkToken"
        }),

        ...mapMutations({
            setAuthenticated: "auth/setAuthenticated",
            updateUser: "auth/updateUser",
            setCompanyID: "mobivas/setCompanyID",
            setDateFrom: "mobivas/setDateFrom",
            setDateTo: "mobivas/setDateTo",
            setMetricIDsDelimited: "mobivas/setMetricIDsDelimited",
            setOperatorsDelimited: "mobivas/setOperatorsDelimited",
            setShortCodesDelimited: "mobivas/setShortCodesDelimited",
            setSubscriptionServicesDelimited: "mobivas/setSubscriptionServicesDelimited"
        }),

        validateKey(key) {
            const isEnterKey = ["Enter"].indexOf(key.code) > -1;

            if (isEnterKey === true)
                this.handleUserHistory()
            else
                this.validateMsidn()
        },
    },
    computed: {
        dateRangeText() {
            return this.dates.join(' - ')
        },
    },
    mounted() {

        var currentDate = new Date().toJSON().slice(0, 10);
        this.setDateFrom(moment(currentDate, 'YYYY-MM-DDT21:00:00Z').add(-3, 'h').format('YYYY-MM-DDT21:00:00Z'))
        this.setDateTo(moment(currentDate, 'YYYY-MM-DDT20:59:59Z').format('YYYY-MM-DDT20:59:59Z'))

        this.checkToken()
            .then((response) => {

                if (response == '')
                    this.$router.push({
                        name: "Login"
                    })
            }),

            this.CompanyListResults().then((response) => {

                Object.keys(response).map((key) => {
                    this.CompanyItem.pkn_id = response. [key].pkn_id
                    this.CompanyItem.name = response. [key].s_description
                    this.CompanyList.push({
                        name: response. [key].s_description,
                        pkn_id: response. [key].pkn_id
                    })
                })
            })

    }
}
</script>

<style>
.results tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05);
}
</style>>

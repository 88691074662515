<template>
<v-container fluid>
    <v-row align="center">

        <v-col cols="12" sm="12" md="8">
            <v-card class="elevation-1">
                <v-toolbar color="grey darken-1" flat dense>
                    <v-card-title class="text-body-1 black--text">Account Details</v-card-title>
                </v-toolbar>
                <v-card-text>
                    <v-form>

                        <v-row align="center">

                            <v-col cols="12">
                                <v-select v-model="selectedCompany" outlined dense small-chips :items="CompanyList" item-value="pkn_cid" item-text="name"></v-select>
                            </v-col>
                        </v-row>

                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" @click="fetchResults()">SHOW</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>

    <v-row align="center">
        <v-col cols="12" sm="12" md="12">
            <v-toolbar class="grey darken-1" flat dense>
                <v-toolbar-title class="text-body-1">Applications</v-toolbar-title>
            </v-toolbar>

            <v-data-table hide-default-footer :headers="headers1" :items="ApplicationsResults" class="elevation-1 results" :loading="loading" loading-text="Loading... Please wait">

                <template v-slot:item.b_sendSTOP="" v-if=true>
                    <v-btn class="ma-2" color="green" dark small>
                        <v-icon dark>mdi-checkbox-marked-circle</v-icon>
                    </v-btn>
                </template>
                <template v-slot:item.b_sendSTOP="{  }" v-else>
                    <v-icon dark>mdi-minus-circle</v-icon>
                    <v-btn class="ma-2" color="red" dark>
                        <v-icon dark>mdi-checkbox-minus-circle</v-icon>
                    </v-btn>
                </template>

                <template v-slot:item.b_active="" v-if=true>
                    <v-btn class="ma-2" color="green" dark small>
                        <v-icon dark>mdi-checkbox-marked-circle</v-icon>
                    </v-btn>
                </template>
                <template v-slot:item.b_active="{  }" v-else>
                    <v-icon dark>mdi-minus-circle</v-icon>
                    <v-btn class="ma-2" color="red" dark small>
                        <v-icon dark>mdi-checkbox-minus-circle</v-icon>
                    </v-btn>
                </template>

            </v-data-table>
        </v-col>

    </v-row>

    <v-row align="center">
        <v-col cols="12" sm="12" md="12">
            <v-toolbar class="grey darken-1" flat dense>
                <v-toolbar-title class="text-body-1">Subscription Services</v-toolbar-title>
            </v-toolbar>

            <v-data-table hide-default-footer :headers="headers2" :items="ListSubscriptionServicesResults" class="elevation-1 results" :loading="loading" loading-text="Loading... Please wait">
            </v-data-table>
        </v-col>

    </v-row>

    <v-row align="center" v-show="noshow">
        <v-col cols="12" sm="12" md="12">
            <v-toolbar class="grey darken-1" flat dense>
                <v-toolbar-title class="text-body-1">Subscription Services</v-toolbar-title>
            </v-toolbar>

            <v-data-table hide-default-footer :headers="headers3" :items="ListShortCodeResults" class="elevation-1 results" :loading="loading" loading-text="Loading... Please wait">
            </v-data-table>
        </v-col>

    </v-row>

</v-container>
</template>

<script>
import {
    mapMutations,
    mapGetters,
    mapActions
} from 'vuex'

export default {
    data() {
        return {

            noshow: false,
            headers1: [{
                    text: 'service id',
                    align: 'left',
                    sortable: false,
                    value: 'pkn_id'
                },
                {
                    text: 'Description',
                    align: 'left',
                    sortable: false,
                    value: 's_description'
                },
                {
                    text: 'MO url',
                    align: 'left',
                    sortable: false,
                    value: 's_moURL'
                },
                {
                    text: 'DLR url',
                    align: 'left',
                    sortable: false,
                    value: 's_dlrURL'
                },
                {
                    text: 'ShortCode',
                    align: 'center',
                    sortable: false,
                    value: 's_extraParameters'
                },
                {
                    text: 'Send Stop',
                    align: 'center',
                    sortable: false,
                    value: 'b_sendSTOP'
                },
                {
                    text: 'Status',
                    align: 'center',
                    sortable: false,
                    value: 'b_active'
                }
            ],
            headers2: [{
                    text: 'pin id',
                    align: 'left',
                    sortable: false,
                    value: 'pkn_id'
                },
                {
                    text: 'Description',
                    align: 'left',
                    sortable: false,
                    value: 's_description'
                },
                {
                    text: 'Keyword',
                    align: 'left',
                    sortable: false,
                    value: 'fks_keyword'
                },
                {
                    text: 'ShortCode',
                    align: 'left',
                    sortable: false,
                    value: 'fks_shortCode'
                }
            ],
            headers3: [{
                    text: 'ShortCode',
                    align: 'left',
                    sortable: false,
                    value: 'pks_shortCode'
                },
                {
                    text: 'service type',
                    align: 'left',
                    sortable: false,
                    value: 'fkn_serviceType'
                },

            ],
            ApplicationsResults: [],
            ListSubscriptionServicesResults: [],
            ListShortCodeResults: [],
           Company: this.getUserCompany(),

            CompanyList: [{
                name: this.getUserCompanyDesc(),
                pkn_cid: this.getUserCompany()
            }],
            selectedCompany: this.getUserCompany(),
            value: null,
            date: "",
            dates: [],
            dateFrom: "",
            dateTo: "",
            payload: {},
            loading: false,
            menu: false,
            export_json_show: false,
            export_json_filename: '',
            export_json_worksheet: '',
            export_json_fields: [],
        }
    },
    methods: {

        logout() {
            this.setAuthenticated(false)
            this.updateField('', 'token')
            this.updateField('', 'tokentimestamp')
            this.updateField('', 'username')
            this.updateField('', 'password')
        },

        getCompanyNameID(pkn_cid) {
            var key = ''
            for (var i = 0; i < this.CompanyList.length; i++) {
                for (key in this.CompanyList[i]) {
                    if (key == 'name' && this.CompanyList[i][key] == pkn_cid) return this.CompanyList[i]['pkn_cid'];
                    if (key == 'pkn_cid' && this.CompanyList[i][key] == pkn_cid) return this.CompanyList[i]['name'];
                }
            }
        },
        getSelectedValue(pkn_cid) {
            this.Company = pkn_cid
        },
        getSelectedDateRange() {
            console.log('-')
        },
        fetchResults() {

            console.log('Fetching')

            this.ApplicationsResults = []
            this.loading = true

            this.setCompanyID(isNaN(parseInt(this.Company)) ? null : parseInt(this.Company))

            this.ListApplicationByCompanyID().then((response) => {

                this.ApplicationsResults = response
                this.loading = false
            })

            this.ListSubscriptionServicesByCompanyID().then((response) => {

                this.ListSubscriptionServicesResults = response

            })

            this.ListListShortCodeResultsByCompanyID().then((response) => {

                this.ListShortCodeResults = response

            })
            this.loading = false

        },
        ...mapGetters({
            getUserCompany: "auth/getUserCompany",
            getUserCompanyDesc: "auth/getUserCompanyDesc",
        }),           
        ...mapActions({
            CompanyListResults: "mobivas/CompanyListResults",
            ListApplicationByCompanyID: "mobivas/ListApplicationByCompanyID",
            ListSubscriptionServicesByCompanyID: "mobivas/ListSubscriptionServicesByCompanyID",
            ListListShortCodeResultsByCompanyID: "mobivas/ListListShortCodeResultsByCompanyID",
            checkToken: "auth/checkToken"

        }),
        ...mapMutations({
            setAuthenticated: "auth/setAuthenticated",
            updateUser: "auth/updateUser",
            setCompanyID: "mobivas/setCompanyID",
            setMetricIDsDelimited: "mobivas/setMetricIDsDelimited",
            setOperatorsDelimited: "mobivas/setOperatorsDelimited",
            setShortCodesDelimited: "mobivas/setShortCodesDelimited",
            setSubscriptionServicesDelimited: "mobivas/setSubscriptionServicesDelimited"
        }),

        validateKey(key) {
            const isEnterKey = ["Enter"].indexOf(key.code) > -1;

            if (isEnterKey === true)
                this.handleUserHistory()
            else
                this.validateMsidn()
        },
    },
    computed: {},
    mounted() {

        this.checkToken()
            .then((response) => {

                if (response == '')
                    this.$router.push({
                        name: "Login"
                    })
            })
    }
}
</script>

<style>
.results tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05);
}
</style>>

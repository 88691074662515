<template>
<v-container fluid>

    <loading :active.sync="isLoading" :is-full-page="fullPage" color="#a42060" loader='bars'></loading>

    <v-row>

        <v-col cols="12" sm="12" md="12">

            <v-card flat>
                <v-toolbar color="grey darken-1" flat dense>
                    <v-card-title class="text-body-1">Monthly | Last Months</v-card-title>
                </v-toolbar>
                <v-card-text>
                    <v-row v-show="!isSimpleUSer">
                        <v-col cols="8" sm="12" md="8">
                            <v-card>
                                <v-system-bar color="purple white--text darken-1">
                                    Monthly Profit List
                                </v-system-bar>

                                <v-card-text>

                                    <v-data-table hide-default-footer dense :headers="headers" :items="CombinedTotalsMonthlyClientPayOut" class="elevation-2 results">
                                        <template v-slot:item="{ item  }">
                                            <tr>
                                                <td>{{ item.TM.substring(0,7) }}</td>
                                                <td>{{ item.Amount + ' €' }}</td>
                                                <td :class="[item.percent > 0 ? 'green--text' : 'red--text'] ">{{ item.percent + [item.percent != '' ? ' %' : ''] }}</td>
                                            </tr>
                                        </template>
                                    </v-data-table>

                                </v-card-text>
                            </v-card>
                        </v-col>

                        <v-col cols="4" sm="12" md="4">
                            <v-card>

                                <v-system-bar color="purple white--text darken-1">
                                    Monthly Profit Chart
                                </v-system-bar>

                                <div>
                                    <line-chart class="Chart" :chart-data="datacollection0" :height="300">
                                    </line-chart>
                                </div>

                            </v-card>

                        </v-col>
                    </v-row>

                </v-card-text>

            </v-card>
        </v-col>

    </v-row>

    <v-row >

        <v-col cols="12" sm="12" md="12">

            <v-card flat>
                <v-toolbar color="grey darken-1" flat dense>
                    <v-card-title class="text-body-1">Weekly Charts</v-card-title>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="3" sm="12" md="3">
                            <v-card class="">

                                <v-system-bar color="purple white--text darken-1">
                                    End User Spent
                                </v-system-bar>

                                <div>
                                    <line-chart class="Chart" :chart-data="datacollection1" :height="200">
                                    </line-chart>
                                </div>

                            </v-card>

                        </v-col>
                        <v-col cols="3" sm="12" md="3">
                            <v-card class="">

                                <v-system-bar color="purple white--text darken-1">
                                    Profit
                                </v-system-bar>

                                <div>
                                    <line-chart class="Chart" :chart-data="datacollection2" :height="200">
                                    </line-chart>
                                </div>

                            </v-card>

                        </v-col>
                        <v-col cols="3" sm="12" md="3">
                            <v-card class="">
                                <v-system-bar color="purple white--text darken-1">
                                    Billings Delivered
                                </v-system-bar>
                                <div>
                                    <line-chart class="Chart" :chart-data="datacollection3" :height="200">
                                    </line-chart>
                                </div>

                            </v-card>

                        </v-col>
                        <v-col cols="3" sm="12" md="3">
                            <v-card class="">
                                <v-system-bar color="purple white--text darken-1">
                                    Billings Pending
                                </v-system-bar>
                                <div>
                                    <line-chart class="Chart" :chart-data="datacollection4" :height="200">
                                    </line-chart>
                                </div>

                            </v-card>

                        </v-col>
                    </v-row>

                </v-card-text>

            </v-card>
        </v-col>

    </v-row>

    <v-row align="center">

        <v-col cols="12" sm="12" md="12">
            <v-card flat>
                <v-toolbar color="grey darken-1" flat dense>
                    <v-card-title class="text-body-1">Overview | LifeTime</v-card-title>
                </v-toolbar>
                <v-card-text>

                    <v-item-group>
                        <v-container>
                            <v-row>
                                <v-col cols="4" md="4" sm="12">
                                    <v-item>
                                        <v-card>

                                            <v-card-title class="text-body-1">
                                                <v-icon left color="#a42060">
                                                    mdi-currency-eur

                                                </v-icon>
                                                {{TotalUserSpent.toLocaleString("el-GR")}}
                                            </v-card-title>
                                            <v-card-subtitle class="caption" style="color: #a42060"><b>END USER SPEND</b>
                                            </v-card-subtitle>
                                        </v-card>
                                    </v-item>
                                </v-col>

                                <v-col cols="4" md="4" sm="12"  v-show="!isSimpleUSer">
                                    <v-item>
                                        <v-card>

                                            <v-card-title class="text-body-1">
                                                <v-icon left color="#a42060">
                                                    mdi-currency-eur
                                                </v-icon>

                                                {{TotalPaydashProfit.toLocaleString("el-GR")}}

                                            </v-card-title>
                                            <v-card-subtitle class="caption" style="color: #a42060"><b>PROFIT</b>
                                            </v-card-subtitle>
                                        </v-card>
                                    </v-item>
                                </v-col>

                                <v-col cols="4" md="4" sm="12">
                                    <v-item>
                                        <v-card>

                                            <v-card-title class="text-body-1">

                                                {{parseInt(TotalBillingsDelivered)}}

                                            </v-card-title>
                                            <v-card-subtitle class="caption" style="color: #a42060"><b>BILLING DLR DELIVERED</b>
                                            </v-card-subtitle>
                                        </v-card>
                                    </v-item>
                                </v-col>

                            </v-row>
                        </v-container>
                    </v-item-group>

                </v-card-text>

            </v-card>
        </v-col>
    </v-row>

</v-container>
</template>

<script>
import {
    mapActions,
    mapMutations,
    mapGetters
} from 'vuex'

import LineChart from '../plugins/LineChart.js'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import moment from 'moment'

export default {

    components: {
        LineChart,
        Loading
    },

    data() {

        return {
            moment: moment,
            isLoading: true,
            fullPage: true,

            headers: [{
                    text: 'Period',
                    align: 'left',
                    sortable: false,
                    value: 'TM'
                },
                {
                    text: 'Amount',
                    align: 'left',
                    sortable: false,
                    value: 'Amount'
                },
                {
                    text: '%',
                    align: 'left',
                    sortable: false,
                    value: 'percent'
                }
            ],
            datacollection0: {},
            datacollection1: {},
            datacollection2: {},
            datacollection3: {},
            datacollection4: {},

            ListOperatorData: [],
            ListSubscriptionServiceData: [],
            ListShortCodeData: [],
            ListMetricData: [],
            CombinedTotalsData: [],
            CombinedTotalsMonthlyProfit: [],
            CombinedTotalsMonthlyClientPayOut:[],
            CombinedTotalsMonthlyDates: [],
            CombinedTotalsMonthlyAmount: [],

            CombinedDates: [],
            CombinedUserSpent: [],
            CombinedBillingsDelivered: [],
            CombinedBillingsPending: [],
            CombinedProfit: [],

            TotalPaydashProfit: 0,
            TotalClientPayOut: 0,

            TotalUserSpent: 0,
            TotalBillingsDelivered: 0,

            date: "",
            dates: [],
            dateFrom: "",
            dateTo: "",
            payload: {},
            loading: false,
            menu: false
        }
    },

    methods: {

        logout() {
            this.setAuthenticated(false)
            this.updateField('', 'token')
            this.updateField('', 'tokentimestamp')
            this.updateField('', 'username')
            this.updateField('', 'password')
        },

        fecthAll() {

            this.ListOperator().then((response) => {
                    var OperatorsDelimited = ''
                    Object.keys(response).map((key) => {
                        OperatorsDelimited = OperatorsDelimited + response. [key].pkn_id + ';'
                        this.ListOperatorData.push({
                            pkn_id: response. [key].pkn_id

                        })
                    })
                    this.setOperatorsDelimited(OperatorsDelimited.slice(0, -1))

                }),

                this.ListSubscriptionService().then((response) => {

                    var SubscriptionServicesDelimited = ''
                    Object.keys(response).map((key) => {
                        SubscriptionServicesDelimited = SubscriptionServicesDelimited + response. [key].pkn_id + ';'
                        this.ListSubscriptionServiceData.push({
                            pkn_id: response. [key].pkn_id
                        })
                    })
                    this.setSubscriptionServicesDelimited(SubscriptionServicesDelimited.slice(0, -1))
              
                }),

                this.ListShortCode().then((response) => {
                    var ShortCodesDelimited = ''
                    Object.keys(response).map((key) => {
                        ShortCodesDelimited = ShortCodesDelimited + response. [key].pks_shortCode + ';'
                        this.ListShortCodeData.push({
                            pks_shortCode: response. [key].pks_shortCode
                        })
                    })
                    this.setShortCodesDelimited(ShortCodesDelimited.slice(0, -1))  
                }),

                this.ListMetric().then((response) => {
                    var MetricIDsDelimited = ''
                    Object.keys(response).map((key) => {
                        MetricIDsDelimited = MetricIDsDelimited + response. [key].pkn_id + ';'
                        this.ListMetricData.push({
                            pkn_id: response. [key].pkn_id
                        })
                    })
                    this.setMetricIDsDelimited(MetricIDsDelimited.slice(0, -1))
                }),

                this.CombinedTotals().then((response) => {

                    Object.keys(response).map((key) => {

                        if (response. [key].Metric == "ClientPayOut" && response. [key].TM == 'Total') {
                            this.TotalClientPayOut = response. [key].Amount.toFixed(2)
                        }

                        if (response. [key].Metric == "MobivasProfit" && response. [key].TM == 'Total') {
                            this.TotalPaydashProfit = response. [key].Amount.toFixed(2)
                        }
                        if (response. [key].Metric == "UserSpent" && response. [key].TM == 'Total') {
                            this.TotalUserSpent = response. [key].Amount.toFixed(2)
                        }
                        if (response. [key].Metric == "BillingsDelivered" && response. [key].TM == 'Total') {
                            this.TotalBillingsDelivered = response. [key].Amount.toFixed(2)
                        }


                        if (response. [key].Metric == "MobivasProfit" && response. [key].TM != 'Total') {

                            this.CombinedTotalsMonthlyDates.push(
                                response. [key].TM.substring(0, 7)
                            )

                            this.CombinedTotalsMonthlyAmount.push(
                                response. [key].Amount.toFixed(2)
                            )

                            this.CombinedTotalsMonthlyProfit.push({
                                TM: response. [key].TM,
                                Amount: response. [key].Amount.toFixed(2),
                                percent: "0"

                            })
                        }
                    })

                    var previous = NaN
                    var percent = NaN
                    Object.keys(this.CombinedTotalsMonthlyProfit).map((key) => {
                        percent = 100 - ((this.CombinedTotalsMonthlyProfit. [key].Amount * 100) / previous)
                        if (isNaN(previous))
                            this.CombinedTotalsMonthlyProfit. [key].percent = ''
                        else {
                            if (previous > this.CombinedTotalsMonthlyProfit. [key].Amount) {
                                percent = (percent) * (-1)
                                this.CombinedTotalsMonthlyProfit. [key].percent = percent.toFixed(2)
                            } else
                                this.CombinedTotalsMonthlyProfit. [key].percent = percent.toFixed(2)
                        }
                        previous = this.CombinedTotalsMonthlyProfit. [key].Amount
                    })
                    this.CombinedTotalsMonthlyProfit = this.CombinedTotalsMonthlyProfit.reverse()
                    //show Graph Monthly Profit
                    this.isLoading = false

                }),

                this.Combined().then((response) => {
                    console.log('Get combined')

                    Object.keys(response).map((key) => {

                        if (response. [key].Metric == "MobivasProfit" && response. [key].TM != 'Total') {

                            this.CombinedProfit.push(
                                response. [key].Amount.toFixed(2)
                            )
                        }

                        if (response. [key].Metric == "UserSpent" && response. [key].TM != 'Total') {

                            this.CombinedUserSpent.push(
                                response. [key].Amount.toFixed(2)
                            )
                        }

                        if (response. [key].Metric == "BillingsPending" && response. [key].TM != 'Total') {

                            this.CombinedBillingsPending.push(
                                response. [key].Amount
                            )
                        }

                        if (response. [key].Metric == "BillingsDelivered" && response. [key].TM != 'Total') {

                            this.CombinedDates.push(
                                response. [key].TM.substring(0, 10)
                            )
                            this.CombinedBillingsDelivered.push(
                                response. [key].Amount
                            )
                        }
                    })
                })

        },
        RenderCharts() {
            console.log('Render')

            this.datacollection0 = {
                    labels: this.CombinedTotalsMonthlyDates,
                    datasets: [{
                        label: 'Monthly Profit (€)',
                        borderColor: '#FC2525',
                        pointBackgroundColor: 'white',
                        borderWidth: 1,
                        pointBorderColor: 'white',
                        data: this.CombinedTotalsMonthlyAmount,
                    }]
                }, {
                    responsive: true,
                    maintainAspectRatio: false
                },

                this.datacollection1 = {
                    labels: this.CombinedDates,
                    datasets: [{
                        label: 'End User Spent (€)',
                        borderColor: '#FC2525',
                        pointBackgroundColor: 'white',
                        borderWidth: 1,
                        pointBorderColor: 'white',
                        data: this.CombinedUserSpent,
                    }]
                }, {
                    responsive: true,
                    maintainAspectRatio: false
                },
                this.datacollection2 = {
                    labels: this.CombinedDates,
                    datasets: [{
                        label: 'Profit (€)',
                        borderColor: '#FC2525',
                        pointBackgroundColor: 'white',
                        borderWidth: 1,
                        pointBorderColor: 'white',
                        data: this.CombinedProfit,
                    }]
                }, {
                    responsive: true,
                    maintainAspectRatio: false
                },
                this.datacollection3 = {
                    labels: this.CombinedDates,
                    datasets: [{
                        label: 'Billings Delivered',
                        borderColor: '#FC2525',
                        pointBackgroundColor: 'white',
                        borderWidth: 1,
                        pointBorderColor: 'white',
                        data: this.CombinedBillingsDelivered,
                    }]
                }, {
                    responsive: true,
                    maintainAspectRatio: false
                },
                this.datacollection4 = {
                    labels: this.CombinedDates,
                    datasets: [{
                        label: 'Billings Pending',
                        borderColor: '#FC2525',
                        pointBackgroundColor: 'white',
                        borderWidth: 1,
                        pointBorderColor: 'white',
                        data: this.CombinedBillingsPending,
                    }]
                }, {
                    responsive: true,
                    maintainAspectRatio: false
                },
                this.loading = "false"
        },
        getSelectedValue(pkn_id) {
            this.Company = pkn_id
        },
        getSelectedDateRange() {
            console.log('-')
        },
        ...mapActions({
            ListOperator: "mobivas/ListOperator",
            ListSubscriptionService: "mobivas/ListSubscriptionService",
            ListShortCode: "mobivas/ListShortCode",
            CombinedTotals: "mobivas/CombinedTotals",
            Combined: "mobivas/Combined",
            ListMetric: "mobivas/ListMetric",
            checkToken: "auth/checkToken"
        }),
        ...mapMutations({
            setAuthenticated: "auth/setAuthenticated",
            updateUser: "auth/updateUser",
            setDateFrom: "mobivas/setDateFrom",
            setDateTo: "mobivas/setDateTo",
            setMetricIDsDelimited: "mobivas/setMetricIDsDelimited",
            setOperatorsDelimited: "mobivas/setOperatorsDelimited",
            setShortCodesDelimited: "mobivas/setShortCodesDelimited",
            setSubscriptionServicesDelimited: "mobivas/setSubscriptionServicesDelimited"
        }),
        ...mapGetters({
            getUser: "auth/getUser",
            isSimpleUser: "auth/isSimpleUser"
        }),        
        validateKey(key) {
            const isEnterKey = ["Enter"].indexOf(key.code) > -1;

            if (isEnterKey === true)
                this.handleUserHistory()
            else
                this.validateMsidn()
        },
    },
    computed: {
        dateRangeText() {
            return this.dates.join(' - ')
        },
    },
    mounted() {
        this.checkToken()
            .then((response) => {

                if (response == '') {

                    this.logout()

                    this.$router.push({
                        name: "Login"
                    })
                }
            }),

        setTimeout(() => {
            this.fecthAll()
        }, 1000);            
        setTimeout(() => {
            this.RenderCharts()
        }, 3000);
    }

}
</script>

<style>
.results tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05);
}

.results {
    font-weight: 500;
}

.Chart {
    background: #2a303b;
    border-radius: 0px;
    box-shadow: 0px 2px 15px rgba(25, 25, 25, 0.27);
    margin: 0px 0;
}

.Chart h2 {
    margin-top: 0;
    padding: 15px 0;
    color: rgba(255, 0, 0, 0.5);
    border-bottom: 1px solid #323d54;
}
</style>

<template>
<v-container fill-height fluid>
    <v-row align="center" justify="center">

        <v-col align="center" justify="center" cols="12" class="justify-center">
            <v-img :src="require('@/assets/mobivas-logo.png')" style="width:20%"></v-img>
        </v-col>

    </v-row>
</v-container>
</template>

<script>
import {
    mapGetters,
    mapActions,
    //mapMutations
} from 'vuex'

export default {
    methods: {

        ...mapActions({
            login: "auth/login",
            checkToken: "auth/checkToken",
        }),
        validateKey(key) {
            const isEnterKey = ["Enter"].indexOf(key.code) > -1;

            if (isEnterKey === true)
                this.handleUserHistory()
            else
                this.validateMsidn()
        },
    },
    computed: {
        ...mapGetters({
            getUser: "auth/getUser"
        }),
    },
    mounted() {
        this.drawer = true,
            this.checkToken()
            .then((response) => {

                if (response == '')
                    this.$router.push({
                        name: "Login"
                    }).catch(() => {});
            })

    }
}
</script>

<template>
<v-app id="inspire">
    <v-main>
        <v-container class="fill-height" fluid>
            <v-row align="center" justify="center">
                <v-col cols="6" sm="12" md="4">
                    <v-card class="elevation-12">
                        <v-toolbar color="primary" dark flat>
                            <v-col>
                                <v-card-title class="black--text pa-0">Mobivas Reporting</v-card-title>
                            </v-col>
                            <v-col class="shrink">
                                <v-img :src="require('@/assets/mobivas-logo.png')" max-width="90" class="ml-0"></v-img>
                            </v-col>

                        </v-toolbar>
                        <v-card-text>

                            <v-form>
                                <v-text-field label="Username" name="userName" prepend-icon="mdi-account" type="text" @input="updateField($event, 'userName')"></v-text-field>

                                <v-text-field id="password" label="Password" name="password" prepend-icon="mdi-lock" type="password" @input="updateField($event, 'password')"></v-text-field>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="handleLogin()">Login</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-main>

    <v-snackbar v-model="snackbar" :absolute=true :color=snackbarColour>
        {{ snackbarText }}
    </v-snackbar>
</v-app>
</template>

<script>
import {
    mapMutations,
    mapActions
} from 'vuex'
import moment from 'moment'

export default {
    data: () => ({
        snackbar: false,
        snackbarText: "",
        snackbarColour: "",
        dropdown_status: [{
                value: true,
                text: "Banned"
            },
            {
                value: false,
                text: "Active"
            },
        ]
    }),
    methods: {
        ...mapMutations({
            setAuthenticated: "auth/setAuthenticated",
            setUserType: "auth/setUserType",
            setUserCompany: "auth/setUserCompany",
            setUserCompanyDesc: "auth/setUserCompanyDesc",
            updateUser: "auth/updateUser",
        }),
        ...mapActions({
            login: "auth/login",
            CompanyListResults: "mobivas/CompanyListResults",
        }),
        updateField(value, fieldName) {
            this.updateUser({
                [fieldName]: value
            });
        },
        handleLogin() {

            this.login()
                .then((response) => {

                    if (Object.keys(response).length == 4) {

                        this.updateField(response.username, 'username')
                        this.updateField(response.userFullname, 'userFullname')
                        this.updateField(response.token, 'token')
                        this.updateField(moment().format('YYYY-MM-DD HH:mm:ss.000'), 'logintimestamp')
                        this.setAuthenticated(true);
                        if (response.userFullname == 'Reporting Tool User') {
                            this.setUserType(true);
                            this.CompanyListResults().then((response) => {
                                console.log('login:'+ response[0].s_description)
                                this.setUserCompany(response[0].pkn_id) 
                                this.setUserCompanyDesc(response[0].s_description) 
                            })
                        }
                        else {
                            this.setUserType(false);
                        }
                        this.$router.push({name: "Home"}).catch(()=>{});    
                    } else
                        this.showErrorMessage()
                })

        },

        showErrorMessage() {
            this.snackbarText = "Login failed, please check your credentials"
            this.snackbarColour = "red darken-2"
            this.snackbar = true
        }
    },
}
</script>

import apiRequest from "./apiRequest"

export default {

    GET_results: (payload, headers, path) => {
        return apiRequest.get(window.apiUrl + path, headers)
    },
    JSON_results: (payload, headers, path) => {
        return apiRequest.postJson(window.apiUrl + path, payload, headers)
    }        
}
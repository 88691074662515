import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

import app from './app.js'
import auth from './auth.js'
import mobivas from './mobivas.js'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
    key: "mobivas-reporting-vue",
    storage: window.localStorage,
    modules: [
        "app",
        "auth"
    ]
})

export default new Vuex.Store({
    modules: {
        app,
        auth,
        mobivas
    },
    plugins: [vuexLocal.plugin],
    strict: true
})
import authApi from '../plugins/api/authApi'
import moment from 'moment'

export default {
    namespaced: true,
    strict: true,

    state: {
        isAuthenticated: false,
        isSimpleUser: true,
        userCompany: '',
        userCompanyDesc: '',
        user: {}
    },
    getters: {
        getUser(state) {
            return state.user
        },
        getUserCompany(state) {
            return state.userCompany
        },
        getUserCompanyDesc(state) {
            return state.userCompanyDesc
        },
        getAccessToken(state) {
            return state.user.token
        },
        isAuthenticated(state) {
            return state.isAuthenticated
        },
        isSimpleUser(state) {
            return state.isSimpleUser
        },

    },
    mutations: {
        setAuthenticated(state, payload) {
            state.isAuthenticated = payload
        },

        setUserType(state, payload) {
            state.isSimpleUser = payload
        },
        setUser: (state, payload) => {
            state.user = payload
        },
        setUserCompany: (state, payload) => {
            state.userCompany = payload
        },
        setUserCompanyDesc: (state, payload) => {
            state.userCompanyDesc = payload
        },
        updateUser: (state, payload) => {
            state.user = Object.assign({}, state.user, payload);
        }
    },

    actions: {
        login: ({ getters }) => {
            let data = {
                username: getters.getUser.userName,
                password: getters.getUser.password,
            }
            return authApi.login(data, "")

            .then((response) => {

                    return response

                })
                .catch((error) => {

                    return error
                })
        },
        checkToken: ({ getters }) => {

            if (moment.duration(moment().diff(getters.getUser.logintimestamp)).asMinutes() > 360) {
                return ''
            } else {
                return getters.getUser.token
            }

        }

    }
}
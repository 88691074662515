<template>
    <v-list class="mx-1 pt-12" nav dense>
        <div class="justify-center">
            <v-img :src="require('@/assets/mobivas-logo.png')" style="width:70%"></v-img>
        </div>
        <v-spacer style="padding-top:20px">
        </v-spacer>
        <v-subheader>Menu</v-subheader>
        <v-divider style="padding-bottom:10px"></v-divider>

        <v-list-item-group v-model="item" color="primary" v-show="!isSimpleUser">
            <v-list-item v-for="(item, i) in AdminItems" :key="i" router :to="item.route">
                <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list-item-group>

        <v-list-item-group v-model="item" color="primary" v-show="isSimpleUser">
            <v-list-item v-for="(item, i) in UserItems" :key="i" router :to="item.route">
                <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list-item-group>

    </v-list>

</template>

<script>
import {
    mapGetters
} from 'vuex'

export default {
    data: () => ({

        item: 1,
        AdminItems: [{ id: 1,text: 'Dashboard', icon: 'mdi-view-dashboard', route: 'dashboard' },
                     { id: 2,text: 'Aggregated Billings',icon: 'mdi-chart-bar', route: 'aggregatedbillings' },
                     { id: 3,text: 'HLR Lookups',icon: 'mdi-earth',route: 'hlrLookups'},
                     { id: 4,text: 'Operator Compare',icon: 'mdi-compare-vertical', route: 'operator-compare' },
                     { id: 5,text: 'Account Details',icon: 'mdi-account-circle',route: 'account-details' },
                     { id: 6,text: 'Billing discrepancies',icon: 'mdi-chart-gantt',route: 'billing-discrepancies' },
                     { id: 7,text: 'Banned Subscribers',icon: 'mdi-account-alert',route: 'banned-subscribers' },
                     { id: 8,text: 'MO Messages',icon: 'mdi-alert-octagon',route: 'mo-messages' },
                     { id: 9,text: 'Unsubscribe Tool',icon: 'mdi-close-octagon',route: 'unsubscribe' }],

        UserItems:  [{ id: 1 ,text: 'Dashboard',icon: 'mdi-view-dashboard',route: 'dashboard-user' },
                     { id: 10,text: 'Reports',icon: 'mdi-chart-bar',route: 'reports-user' },
                     //{ id: 2 ,text: 'Aggregated Billings',icon: 'mdi-chart-bar',route: 'aggregatedbillings-user' },
                     { id: 5 ,text: 'Account Details',icon: 'mdi-account-circle',route: 'account-details-user' }],
    }),

computed: {

        ...mapGetters({
        isSimpleUser: "auth/isSimpleUser"
        }),

},

    methods: {



        fetch() {

            console.log('---->1<-----')
            console.log(this.isSimpleUser)
            console.log('---->2<-----')

        }
    },

    beforeMount () {

        this.fetch()

    }
    
}
</script>

import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import mockData from './mockData'

export default {
    async request({method, url, payload}) {
        let mock = new MockAdapter(axios);

        // init mock helper classes
        mockData.init(mock);

        const init = {
            method: method,
            url: url
        }

        if (typeof payload !== 'undefined' && payload !== null) init.data = payload

        return axios (init)
        .then (response => {
            mock.restore();
            return response.data;
        })
        .catch (() => {
            mock.restore();
        });
    },
    get(url, headers) {
        return this.request({method: 'GET', url: url, headers: headers})
    },
    post(url, payload, headers) {
        return this.request({method: 'POST', url: url, payload: payload, headers: headers})
    },
    postForm(url, data, headers) {
        const payload = (typeof data == 'string') ? data : Object.keys(data).map((k) => {
            return encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
        }).join('&')

        headers = headers || {}
        headers['Content-Type'] = 'application/x-www-form-urlencoded'

        return this.request({method: 'POST', url: url, payload: payload, headers: headers})
    },
    postJson(url, data, headers) {
        const payload = (typeof data == 'string') ? data : JSON.stringify(data)

        headers = headers || {}
        headers['Content-Type'] = 'application/json'

        return this.request({method: 'POST', url: url, payload: payload, headers: headers})
    }
}
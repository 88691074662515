import mobivasApi from '../plugins/api/mobivasApi'
import moment from 'moment'

export default {
    namespaced: true,
    strict: true,

    state: {

        dateFrom: '',
        dateTo: '',
        companyId: '',
        MetricIDsDelimited: '200;201;202;203;204;205;206;207;208;209;210;211;212;213;214;215;216;217;218;219;220;221;222;223;224;225;226;227;228;229;230;231;232;233;234;235;236;237;238;239;240;241;242;243;246;247;248;249;250;251;252;253;254;255;256;257;258;259;260;261;264;270;',
        OperatorsDelimited: '1;2;3',
        ShortCodesDelimited: '',
        SubscriptionServicesDelimited: '',
        SelectedReportInterval: 2,
        SelectedReportType: 1,
        msisdn: '',
    },

    getters: {
        getDateFrom(state) {
            return state.dateFrom
        },
        getDateTo(state) {
            return state.dateTo
        },
        getMSISDN(state) {
            return state.msisdn
        },
        getCompanyId(state) {
            return state.companyId
        },
        getMetricIDsDelimited(state) {
            return state.MetricIDsDelimited
        },
        getOperatorsDelimited(state) {
            return state.OperatorsDelimited
        },
        getShortCodesDelimited(state) {
            return state.ShortCodesDelimited
        },
        getSubscriptionServicesDelimited(state) {
            return state.SubscriptionServicesDelimited
        },
        getSelectedReportType(state) {
            return state.SelectedReportType
        },
        getSelectedReportInterval(state) {
            return state.SelectedReportInterval
        },
    },
    mutations: {
        setDateFrom(state, payload) {
            state.dateFrom = payload
        },
        setDateTo(state, payload) {
            state.dateTo = payload
        },

        setMSISDN(state, payload) {
            state.msisdn = payload
        },
        setCompanyID(state, payload) {
            state.companyId = payload
        },
        setMetricIDsDelimited(state, payload) {
            state.MetricIDsDelimited = payload
        },
        setOperatorsDelimited(state, payload) {
            state.OperatorsDelimited = payload
        },
        setShortCodesDelimited(state, payload) {
            state.ShortCodesDelimited = payload
        },
        setSubscriptionServicesDelimited(state, payload) {
            state.SubscriptionServicesDelimited = payload
        },
        setSelectedReportType(state, payload) {
            state.SelectedReportType = payload
        },
        setSelectedReportInterval(state, payload) {
            state.SelectedReportInterval = payload
        },

    },

    actions: {
        CompanyListResults: ({ rootGetters }) => {

            let headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + rootGetters['auth/getAccessToken']
            }

            let data = {}

            return mobivasApi.GET_results(data, headers, "/list/company")
                .then((response) => {

                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        ListOperator: ({ rootGetters }) => {
            let headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + rootGetters['auth/getAccessToken']
            }

            let data = {}

            return mobivasApi.GET_results(data, headers, "/list/operator")
                .then((response) => {

                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        ListSubscriptionService: ({ rootGetters }) => {
            let headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + rootGetters['auth/getAccessToken']
            }

            let data = {}

            return mobivasApi.GET_results(data, headers, "/list/subscriptionservice")
                .then((response) => {

                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        ListShortCode: ({ rootGetters }) => {
            let headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + rootGetters['auth/getAccessToken']
            }

            let data = {}

            return mobivasApi.GET_results(data, headers, "/list/shortcode")
                .then((response) => {

                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        ListShortCodeByCompany: ({ rootGetters }) => {
            let headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + rootGetters['auth/getAccessToken']
            }

            let data = {}

            return mobivasApi.GET_results(data, headers, "/list/shortcode/byCompany/" + rootGetters['auth/getUserCompany'])
                .then((response) => {

                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        ListMetric: ({ rootGetters }) => {
            let headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + rootGetters['auth/getAccessToken']
            }

            let data = {}

            return mobivasApi.GET_results(data, headers, "/metric")
                .then((response) => {

                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        AggregatedBillingsResults: ({ state, rootGetters }) => {

            let headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + rootGetters['auth/getAccessToken']
            }

            let dF = (state.dateFrom == '' ? new Date().toJSON().slice(0, 10) : state.dateFrom)
            let dT = (state.dateTo == '' ? new Date().toJSON().slice(0, 10) : state.dateTo)

            let data = {
                companyId: isNaN(parseInt(state.companyId)) ? null : parseInt(state.companyId),
                dateFrom: dF,
                dateTo: dT

            }

            return mobivasApi.JSON_results(data, headers, "/reporting/aggregatedBillings")
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        HLRLookupResults: ({ state, rootGetters }) => {

            let headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + rootGetters['auth/getAccessToken']
            }

            let dF = (state.dateFrom == '' ? new Date().toJSON().slice(0, 10) : state.dateFrom)
            let dT = (state.dateTo == '' ? new Date().toJSON().slice(0, 10) : state.dateTo)

            let data = {
                n_companyId: isNaN(parseInt(state.companyId)) ? null : parseInt(state.companyId),
                dFrom: dF,
                dTo: dT
            }

            return mobivasApi.JSON_results(data, headers, "/reporting/hlrLookups")
                .then((response) => {

                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        CombinedTotals: ({ state, rootGetters }) => {
            let headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + rootGetters['auth/getAccessToken']
            }
            var currentDate = new Date().toJSON().slice(0, 10);
            var dateFrom1 = moment(currentDate, 'YYYY-MM-DDT21:00:00Z').add(-7, 'd').add(-3, 'h').format('YYYY-MM-DDT21:00:00Z')
            var dateTo1 = moment(currentDate, 'YYYY-MM-DDT20:59:59Z').format('YYYY-MM-DDT20:59:59Z')

            let data = {
                dateFrom: dateFrom1,
                dateTo: dateTo1,
                isInternal: false,
                metricsDelimited: state.MetricIDsDelimited,
                operatorsDelimited: state.OperatorsDelimited,
                rangeTypeId: 2,
                shortCodesDelimited: state.ShortCodesDelimited,
                subscriptionServicesDelimited: state.SubscriptionServicesDelimited
            }


            return mobivasApi.JSON_results(data, headers, "/reporting/combinedTotals")
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        Combined: ({ state, rootGetters }) => {
            let headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + rootGetters['auth/getAccessToken']
            }
            var currentDate = new Date().toJSON().slice(0, 10);

            var dateFrom = moment(currentDate, 'YYYY-MM-DDT21:00:00Z').add(-7, 'd').add(-3, 'h').format('YYYY-MM-DDT21:00:00Z')
            var dateTo = moment(currentDate, 'YYYY-MM-DDT20:59:59Z').format('YYYY-MM-DDT20:59:59Z')

            let data = {
                dateFrom: dateFrom,
                dateto: dateTo,
                isInternal: false,
                metricsDelimited: state.MetricIDsDelimited,
                operatorsDelimited: state.OperatorsDelimited,
                rangeTypeId: 2,
                shortCodesDelimited: state.ShortCodesDelimited,
                subscriptionServicesDelimited: state.SubscriptionServicesDelimited
            }

            return mobivasApi.JSON_results(data, headers, "/reporting/combined")
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        CombinedReporting: ({ state, rootGetters }) => {
            let headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + rootGetters['auth/getAccessToken']
            }

            let dateFrom = (state.dateFrom == '' ? new Date().toJSON().slice(0, 10) : state.dateFrom)
            let dateTo = (state.dateTo == '' ? new Date().toJSON().slice(0, 10) : state.dateTo)
                //var metricsIDDelimited = ''

            //if (state.SelectedReportType == 1) { metricsIDDelimited = '9;10' }
            //if (state.SelectedReportType == 2) { metricsIDDelimited = '1;4;5;6' }
            //if (state.SelectedReportType == 3) { metricsIDDelimited = '7;8' }

            let data = {
                dateFrom: dateFrom,
                dateto: dateTo,
                isInternal: false,
                metricsDelimited: state.MetricIDsDelimited,
                operatorsDelimited: state.OperatorsDelimited,
                rangeTypeId: state.SelectedReportInterval,
                shortCodesDelimited: state.ShortCodesDelimited,
                subscriptionServicesDelimited: state.SubscriptionServicesDelimited
            }

            return mobivasApi.JSON_results(data, headers, "/reporting/combined")
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        ListApplicationByCompanyID: ({ state, rootGetters }) => {

            let headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + rootGetters['auth/getAccessToken']
            }

            let data = {}

            return mobivasApi.GET_results(data, headers, "/list/application/byCompany/" + parseInt(state.companyId))
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        ListSubscriptionServicesByCompanyID: ({ state, rootGetters }) => {

            let headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + rootGetters['auth/getAccessToken']
            }
            let data = {}

            return mobivasApi.GET_results(data, headers, "/list/subscriptionservice/byCompany/" + parseInt(state.companyId))
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        ListListShortCodeResultsByCompanyID: ({ state, rootGetters }) => {

            let headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + rootGetters['auth/getAccessToken']
            }
            let data = {}

            return mobivasApi.GET_results(data, headers, "/list/shortcode/byCompany/" + parseInt(state.companyId))
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        countDiscrepancies: ({ state, rootGetters }) => {

            let headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + rootGetters['auth/getAccessToken']
            }

            let dF = (state.dateFrom == '' ? new Date().toJSON().slice(0, 10) : state.dateFrom)
            let dT = (state.dateTo == '' ? new Date().toJSON().slice(0, 10) : state.dateTo)

            let data = {
                dateFrom: dF,
                dateTo: dT
            }

            return mobivasApi.JSON_results(data, headers, "/reporting/countDiscrepancies")
                .then((response) => {

                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        OperatorCompare: ({ state, rootGetters }) => {

            let headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + rootGetters['auth/getAccessToken']
            }

            let dF = (state.dateFrom == '' ? new Date().toJSON().slice(0, 10) : state.dateFrom)
            let dT = (state.dateTo == '' ? new Date().toJSON().slice(0, 10) : state.dateTo)

            let data = {
                dFrom: dF,
                dTo: dT,
                companyId: isNaN(parseInt(parseInt(state.companyId))) ? null : parseInt(parseInt(state.companyId)),
            }

            return mobivasApi.JSON_results(data, headers, "/reporting/operatorCompare")
                .then((response) => {

                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        BanList: ({ rootGetters }) => {

            let headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + rootGetters['auth/getAccessToken']
            }

            let data = {}

            return mobivasApi.GET_results(data, headers, "/subscriber/banList")
                .then((response) => {

                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        Messages: ({ state, rootGetters }) => {

            let headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + rootGetters['auth/getAccessToken']
            }

            let data = {
                dFrom: state.dateFrom,
                dTo: state.dateTo,
                msisdn: state.msisdn,
            }

            return mobivasApi.JSON_results(data, headers, "/subscriber/messages")
                .then((response) => {

                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        TPMessages: ({ state, rootGetters }) => {

            let headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + rootGetters['auth/getAccessToken']
            }

            let data = {
                companyID: isNaN(parseInt(parseInt(state.companyId))) ? null : parseInt(parseInt(state.companyId)),
                dateFrom: state.dateFrom,
                dateTo: state.dateTo,
                msisdn: state.msisdn,
            }

            return mobivasApi.JSON_results(data, headers, "/subscriber/tpmessages")
                .then((response) => {

                    return response
                })
                .catch((error) => {
                    return error
                })
        },
    }
}
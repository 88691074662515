<template>
<v-container fluid>
    <v-row align="center">

        <v-col cols="12" sm="8" md="8">
            <v-card class="elevation-1">
                <v-toolbar color="grey darken-1" flat dense>
                    <v-card-title class="text-body-1">Subscriber Messages - TP Messages</v-card-title>
                </v-toolbar>
                <v-card-text>
                    <v-form>

                        <v-row align="center">

                            <v-col cols="12">

                                <v-text-field type="text" label="Msisdn" @change="getSelectedMSISDN" item-value="Msidn" outlined counter maxlength="12" dense>
                                </v-text-field>
                            </v-col>
                        </v-row>

                        <v-row align="center">

                            <v-col cols="12">
                                <v-select @change="getSelectedValue" v-model="Company" :items="CompanyList" item-value="pkn_id" item-text="name" outlined dense label="Company"></v-select>
                            </v-col>
                        </v-row>

                          <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <div>

                                            <v-md-date-range-picker v-model="dateRangeText" @change="getSelectedDateRange" v-bind="attrs" v-on="on"></v-md-date-range-picker>

                                        </div>

                                    </template>

                                </v-menu>
                            </v-col>
                        </v-row>

                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" @click="fetchResults()">SHOW</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>

    <v-row align="center">
        <v-col cols="12" sm="12" md="12">
            <v-toolbar color="grey darken-1 elevation-1" flat>
                <v-toolbar-title class="text-body-1"> Subscriber Messages</v-toolbar-title>
            </v-toolbar>

            <v-data-table hide-default-footer :headers="headersMessages" :items="MessagesResults" class="elevation-1 results" :loading="loading" loading-text="Loading... Please wait">
            </v-data-table>
        </v-col>
    </v-row>

    <v-row align="center">
        <v-col cols="12" sm="12" md="12">
            <v-toolbar color="grey darken-1 elevation-1" flat>
                <v-toolbar-title class="text-body-1"> TP Messages</v-toolbar-title>
            </v-toolbar>

            <v-data-table hide-default-footer :headers="headersTPMessages" :items="TPMessagesResults" class="elevation-1 results" :loading="loading" loading-text="Loading... Please wait">
            </v-data-table>
        </v-col>
    </v-row>

</v-container>
</template>

<script>

import Vue from 'vue';
import VMdDateRangePicker from "v-md-date-range-picker";
import moment from 'moment';
Vue.use(VMdDateRangePicker);

import {
    mapActions,
    mapMutations,

} from 'vuex'

export default {
    data() {
        return {

            export_json_fields: [{
                    label: "Company",
                    field: "Company"
                },
                {
                    label: 'ShortCode',
                    field: 'ShortCode'
                },
                {
                    label: 'MNO',
                    field: 'MNO'
                },
                {
                    label: '# Billings',
                    field: 'SumBillings'
                },
                {
                    label: '(€) User Spent',
                    field: 'SumUserSpent'
                }
            ],

            export_json_worksheet: "export",
            export_json_filename: "export.xlsx",
            export_json_show: false,

            headersMessages: [{
                    text: 'Origin',
                    align: 'left',
                    sortable: false,
                    value: 'origin'
                },
                {
                    text: 'Message id',
                    align: 'left',
                    sortable: false,
                    value: 'pkn_id'
                },
                {
                    text: 'Message',
                    align: 'left',
                    sortable: false,
                    value: 's_message'
                },
                {
                    text: 'User',
                    align: 'left',
                    sortable: false,
                    value: 'fks_user'
                },
                {
                    text: 'Date Sent',
                    align: 'left',
                    sortable: false,
                    value: 'd_dateSent'
                },
                {
                    text: 'Date Enq',
                    align: 'left',
                    sortable: false,
                    value: 'd_dateEnqueued'
                },
                {
                    text: 'Operator',
                    align: 'left',
                    sortable: false,
                    value: 's_description'
                },
                {
                    text: 'ShortCode',
                    align: 'left',
                    sortable: false,
                    value: 'fks_shortCode'
                },
                {
                    text: 'Keyword',
                    align: 'left',
                    sortable: false,
                    value: 'fks_keyword'
                },
            ],
            headersTPMessages: [{
                    text: 'Origin',
                    align: 'left',
                    sortable: false,
                    value: 'origin'
                },
                {
                    text: 'Message id',
                    align: 'left',
                    sortable: false,
                    value: 'pkn_id'
                },
                {
                    text: 'Message',
                    align: 'left',
                    sortable: false,
                    value: 's_message'
                },
                {
                    text: 'User',
                    align: 'left',
                    sortable: false,
                    value: 'fks_user'
                },
                {
                    text: 'Date Sent',
                    align: 'left',
                    sortable: false,
                    value: 'd_dateSent'
                },
                {
                    text: 'Keyword',
                    align: 'left',
                    sortable: false,
                    value: 'fks_keyword'
                },
                {
                    text: 'ShortCode',
                    align: 'left',
                    sortable: false,
                    value: 'fks_shortCode'
                },
                {
                    text: 'Company',
                    align: 'left',
                    sortable: false,
                    value: 'n_company'
                },
                {
                    text: 'Attempt',
                    align: 'left',
                    sortable: false,
                    value: 'n_attempt'
                },
                {
                    text: 'TP Reply',
                    align: 'left',
                    sortable: false,
                    value: 's_TPReply'
                },
                {
                    text: 'Time Elapsed',
                    align: 'left',
                    sortable: false,
                    value: 'n_timeElapsed'
                },
                {
                    text: 'XML URL Enc',
                    align: 'left',
                    sortable: false,
                    value: 's_XMLURLEnc'
                },
                {
                    text: 'XML Raw',
                    align: 'left',
                    sortable: false,
                    value: 's_XMLRaw'
                },
                {
                    text: 'Application',
                    align: 'left',
                    sortable: false,
                    value: 's_companyApplication'
                },
            ],
            MessagesResults: [],
            TPMessagesResults: [],
            Company: null,
            Msisdn: null,
            CompanyItem: {
                pkn_id: null,
                name: null
            },
            CompanyList: [],
            value: null,
            date: "",
            dates: [],
            dateFrom: "",
            dateTo: "",
            payload: {},
            loading: false,
            menu: false
        }
    },
    methods: {

        logout() {
            this.setAuthenticated(false)
            this.updateField('', 'token')
            this.updateField('', 'tokentimestamp')
            this.updateField('', 'username')
            this.updateField('', 'password')
        },

        getSelectedValue(pkn_id) {
            this.Company = pkn_id
        },

        isNumber: function (evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        getSelectedMSISDN(Msisdn) {
            this.Msisdn = Msisdn
        },

        getSelectedDateRange(values) {

            this.setDateFrom(moment(values[0], 'YYYY-MM-DDT21:00:00Z').add(-3, 'h').format('YYYY-MM-DDT21:00:00Z'))
            this.setDateTo(moment(values[1], 'YYYY-MM-DDT20:59:59Z').format('YYYY-MM-DDT20:59:59Z'))

        },

        fetchResults() {

            console.log('Fetching')

            this.export_json_show = false
            this.loading = true

            this.setCompanyID(isNaN(parseInt(this.Company)) ? null : parseInt(this.Company))
            this.setMSISDN(this.Msisdn)

            this.Messages().then((response) => {
                this.MessagesResults = response
                //this.export_json_data = this.MessagesResults
                //this.export_json_worksheet = "SubscriberMessages"
                //this.export_json_filename = "mobivas_export.xlsx"
                //this.export_json_show = true
                this.loading = false
            })

            this.TPMessages().then((response) => {
                this.TPMessagesResults = response
                //this.export_json_data = this.MessagesResults
                //this.export_json_worksheet = "SubscriberMessages"
                //this.export_json_filename = "mobivas_export.xlsx"
                //this.export_json_show = true
                this.loading = false
            })

        },
        ...mapActions({
            CompanyListResults: "mobivas/CompanyListResults",
            Messages: "mobivas/Messages",
            TPMessages: "mobivas/TPMessages",
            checkToken: "auth/checkToken"
        }),

        ...mapMutations({
            setAuthenticated: "auth/setAuthenticated",
            updateUser: "auth/updateUser",
            setCompanyID: "mobivas/setCompanyID",
            setDateFrom: "mobivas/setDateFrom",
            setDateTo: "mobivas/setDateTo",
            setMSISDN: "mobivas/setMSISDN"
        }),

        validateKey(key) {
            const isEnterKey = ["Enter"].indexOf(key.code) > -1;

            if (isEnterKey === true)
                this.handleUserHistory()
            else
                this.validateMsidn()
        },
    },
    computed: {
        dateRangeText() {
            return this.dates.join(' - ')
        },
    },
    mounted() {

        this.checkToken()
            .then((response) => {

                if (response == '')
                    this.$router.push({
                        name: "Login"
                    })
            }),

            this.CompanyListResults().then((response) => {

                Object.keys(response).map((key) => {
                    this.CompanyItem.pkn_id = response. [key].pkn_id
                    this.CompanyItem.name = response. [key].s_description
                    this.CompanyList.push({
                        name: response. [key].s_description,
                        pkn_id: response. [key].pkn_id
                    })

                })
            })

    }
}
</script>

<style>
.results tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05);
}
</style>>

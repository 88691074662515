<template>
    <nav>
        <v-app-bar
            dense
            app
            v-if="$route.name!='Login'"
        >
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>Mobivas Reporting</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="toggleDark()">
            <v-icon>mdi-invert-colors</v-icon>
        </v-btn>
        <v-btn class="ml-3 mr-1" v-if="$route.name!='Login'" @click="logout()">
            Logout
        </v-btn>        
        </v-app-bar>

        <v-navigation-drawer
            v-model="drawer"
            app
            :clipped="clipped"
            v-if="$route.name!='Login'"
        >
            <MenuList/>

        </v-navigation-drawer>
    </nav>
</template>

<script>

import MenuList from '@/components/MenuList.vue'

import { mapMutations,
mapGetters 
} from 'vuex'

export default {
    components: {
        MenuList
    },
    data() {
        return {
            drawer: true,

        }
    },
  computed: {
    ...mapGetters ({
      getUser: "auth/getUser",
    }),
  },    
    methods: {
    ...mapMutations ({
      setAuthenticated: "auth/setAuthenticated",
      setUserType: "auth/setUserType",
      updateUser: "auth/updateUser",
    }),    
    updateField(value, fieldName) {
      this.updateUser({[fieldName]: value});
    },                 
    toggleDark() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark
        },
    logout() {
            console.log('Logout')
            this.setAuthenticated(false)
            this.setUserType(false)
            this.updateField('','token')
            this.updateField('','token-timestamp')
            this.updateField('','username')
            this.updateField('','password')
            this.updateField('','userFullname')
            this.drawer = false
            localStorage.setItem('token', '')
            this.$router.push({name: "Login"})
        },        
    }
}
</script>
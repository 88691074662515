<template>
<v-container fluid>
    <v-row align="center">

        <v-col cols="12" sm="8" md="8">
            <v-card class="elevation-1">
                <v-toolbar color="grey darken-1" flat dense>
                    <v-card-title class="text-body-1 black--text">Data discrepancies between live records & generated report records</v-card-title>
                </v-toolbar>
                <v-card-text>
                    <v-form>

                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <div><br><br>

                                            <v-md-date-range-picker v-model="dateRangeText" @change="getSelectedDateRange" v-bind="attrs" v-on="on"></v-md-date-range-picker>

                                        </div>

                                    </template>

                                </v-menu>
                            </v-col>
                        </v-row>

                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" @click="fetchResults()">SHOW</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>

    <v-row align="center">
        <v-col cols="12" sm="12" md="12">

            <v-data-table hide-default-footer :v-show="false" :headers="headers" :items="countDiscrepanciesResults" class="elevation-1 results" :loading="loading" loading-text="Loading... Please wait">

            </v-data-table>
        </v-col>
    </v-row>

</v-container>
</template>

<script>
import Vue from 'vue';
import VMdDateRangePicker from "v-md-date-range-picker";
import moment from 'moment';
Vue.use(VMdDateRangePicker);

import {
    mapActions,
    mapMutations,
} from 'vuex'

export default {
    data() {
        return {

            headers: [{
                    text: 'Date',
                    align: 'left',
                    sortable: false,
                    value: 'TM'
                },
                {
                    text: 'App Results',
                    align: 'left',
                    sortable: false,
                    value: 'BillingsAll'
                },
                {
                    text: 'Tool Results',
                    align: 'left',
                    sortable: false,
                    value: 'BillingsProcessed'
                },
                {
                    text: 'Diff.',
                    align: 'left',
                    sortable: false,
                    value: 'diff'
                }
            ],
            countDiscrepanciesResults: [],
            value: null,
            date: "",
            dates: [moment().format('YYYY-MM-DD')],
            dateFrom: "",
            dateTo: "",
            payload: {},
            loading: false,
            menu: false
        }
    },
    methods: {
        getSelectedDateRange(values) {

            this.setDateFrom(moment(values[0], 'YYYY-MM-DDT21:00:00Z').add(-3, 'h').format('YYYY-MM-DDT21:00:00Z'))
            this.setDateTo(moment(values[1], 'YYYY-MM-DDT20:59:59Z').format('YYYY-MM-DDT20:59:59Z'))

        },
        fetchResults() {

            console.log('Fetching')

            this.loading = true

            this.countDiscrepancies().then((response) => {
                this.loading = false
                this.countDiscrepanciesResults = response
            })
        },
        ...mapActions({
            countDiscrepancies: "mobivas/countDiscrepancies",
            checkToken: "auth/checkToken"
        }),
        ...mapMutations({
            setAuthenticated: "auth/setAuthenticated",
            updateUser: "auth/updateUser",
            setDateFrom: "mobivas/setDateFrom",
            setDateTo: "mobivas/setDateTo",
        }),

        validateKey(key) {
            const isEnterKey = ["Enter"].indexOf(key.code) > -1;

            if (isEnterKey === true)
                this.handleUserHistory()
            else
                this.validateMsidn()
        },
    },
    computed: {
        dateRangeText() {
            return this.dates.join(' - ')
        },
    },
    mounted() {

        var currentDate = new Date().toJSON().slice(0, 10);
        this.setDateFrom(moment(currentDate, 'YYYY-MM-DDT21:00:00Z').add(-3, 'h').format('YYYY-MM-DDT21:00:00Z'))
        this.setDateTo(moment(currentDate, 'YYYY-MM-DDT20:59:59Z').format('YYYY-MM-DDT20:59:59Z'))

        this.checkToken()
            .then((response) => {

                if (response == '')
                    this.$router.push({
                        name: "Login"
                    })
            }),

            this.countDiscrepancies().then((response) => {

                console.log(response);

            })

    }
}
</script>

<style>
.results tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05);
}
</style>>

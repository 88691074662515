<template>
<v-container fluid>
    <v-row align="center">

        <v-col cols="12" sm="8" md="8">
            <v-card class="elevation-1">
                <v-toolbar color="grey darken-1" flat dense>
                    <v-card-title class="text-body-1 black--text">Compare Operator's Billings per ShortCode</v-card-title>
                </v-toolbar>
                <v-card-text>
                    <v-form>

                        <v-row align="center">

                            <v-col cols="12">
                                <v-select @change="getSelectedValue" v-model="Company" :items="CompanyList" item-value="pkn_id" item-text="name" outlined dense label="Company"></v-select>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <div>

                                            <v-md-date-range-picker v-model="dateRangeText" @change="getSelectedDateRange" v-bind="attrs" v-on="on"></v-md-date-range-picker>

                                        </div>

                                    </template>

                                </v-menu>
                            </v-col>
                        </v-row>

                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" @click="fetchResults()">SHOW</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>

    <v-row v-if="show">
        <v-col cols="4" sm="4" md="4" v-show="showgraph_54126">
            <v-card>

                <v-system-bar color="purple white--text darken-1">
                    SC 54126
                </v-system-bar>

                <div>
                    <line-chart class="Chart" :chart-data="datacollection_54126" :height="300">
                    </line-chart>
                </div>

            </v-card>

        </v-col>

        <v-col cols="4" sm="4" md="4" v-if="showgraph_54010">
            <v-card>

                <v-system-bar color="purple white--text darken-1">
                    SC 54010
                </v-system-bar>

                <div>
                    <line-chart class="Chart" :chart-data="datacollection_54010" :height="300">
                    </line-chart>
                </div>

            </v-card>

        </v-col>

        <v-col cols="4" sm="4" md="4" v-if="showgraph_54021">
            <v-card>

                <v-system-bar color="purple white--text darken-1">
                    SC 54021
                </v-system-bar>

                <div>
                    <line-chart class="Chart" :chart-data="datacollection_54021" :height="300">
                    </line-chart>
                </div>

            </v-card>

        </v-col>

        <v-col cols="4" sm="4" md="4" v-if="showgraph_54200">
            <v-card>

                <v-system-bar color="purple white--text darken-1">
                    SC 54200
                </v-system-bar>

                <div>
                    <line-chart class="Chart" :chart-data="datacollection_54200" :height="300">
                    </line-chart>
                </div>

            </v-card>

        </v-col>

    </v-row>

    <v-row align="center">
        <v-col cols="12" sm="12" md="12">
            <v-toolbar class="grey darken-1" flat dense>
                <v-toolbar-title class="text-body-1">Billings List</v-toolbar-title>
            </v-toolbar>

            <v-data-table :headers="headers" :items="FOperatorCompareResults" disable-pagination class="elevation-1 results" :loading="loading" hide-default-footer loading-text="Loading... Please wait">

            </v-data-table>
        </v-col>
    </v-row>

</v-container>
</template>

<script>
import Vue from 'vue';
import VMdDateRangePicker from "v-md-date-range-picker";
import LineChart from '../plugins/LineChart.js'
import moment from 'moment';
Vue.use(VMdDateRangePicker);

import {
    mapActions,
    mapMutations
} from 'vuex'

export default {
    components: {
        LineChart
    },

    data() {
        return {
            show: false,
            headers: [{
                    text: 'TM',
                    align: 'left',
                    sortable: false,
                    value: 'TM'
                },
                {
                    text: 'ShortCode',
                    align: 'left',
                    sortable: false,
                    value: 'ShortCode'
                },
                {
                    text: 'Operator',
                    align: 'left',
                    sortable: false,
                    value: 'Operator'
                },
                {
                    text: 'Billings',
                    align: 'left',
                    sortable: false,
                    value: 'BillingsDelivered'
                }
            ],
            OperatorCompareResults: [],
            FOperatorCompareResults: [],
            datacollection_54126: [],
            datacollection_54010: [],
            datacollection_54200: [],
            datacollection_54021: [],

            showgraph_54126: false,
            showgraph_54010: false,
            showgraph_54200: false,
            showgraph_54021: false,

            Company: null,
            CompanyItem: {
                pkn_id: null,
                name: null
            },
            CompanyList: [{
                name: "All Companies",
                pkn_id: null
            }],
            value: null,
            date: "",
            dates: [],
            dateFrom: "",
            dateTo: "",
            payload: {},
            loading: false,
            menu: false,
            graph_options: [{
                scales: {
                    xAxes: [{
                        type: 'date',
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: 90
                        }
                    }]
                }
            }]
        }
    },
    methods: {

        logout() {
            this.setAuthenticated(false)
            this.updateField('', 'token')
            this.updateField('', 'tokentimestamp')
            this.updateField('', 'username')
            this.updateField('', 'password')
        },

        getCompanyNameID(pkn_id) {
            var key = ''
            for (var i = 0; i < this.CompanyList.length; i++) {
                for (key in this.CompanyList[i]) {
                    if (key == 'name' && this.CompanyList[i][key] == pkn_id) return this.CompanyList[i]['pkn_id'];
                    if (key == 'pkn_id' && this.CompanyList[i][key] == pkn_id) return this.CompanyList[i]['name'];
                }
            }
        },
        getSelectedValue(pkn_id) {
            this.Company = pkn_id
        },
        getSelectedDateRange(values) {

            this.setDateFrom(moment(values[0], 'YYYY-MM-DDT21:00:00Z').add(-3, 'h').format('YYYY-MM-DDT21:00:00Z'))
            this.setDateTo(moment(values[1], 'YYYY-MM-DDT20:59:59Z').format('YYYY-MM-DDT20:59:59Z'))

        },
        fetchResults() {

            console.log('Fetching')

            this.loading = true

            this.setCompanyID(isNaN(parseInt(this.Company)) ? null : parseInt(this.Company))

            this.OperatorCompare().then((response) => {

                this.OperatorCompareResults = response
                this.FOperatorCompareResults = []

                this.showgraph_54126 = false
                this.showgraph_54010 = false
                this.showgraph_54200 = false
                this.showgraph_54021 = false

                let i = 0;

                while (i < this.OperatorCompareResults.length) {
                    if (this.OperatorCompareResults[i].ShortCode != '54196' &&
                        this.OperatorCompareResults[i].ShortCode != '54988' &&
                        (this.OperatorCompareResults[i].ShortCode == '54200' ||
                            this.OperatorCompareResults[i].ShortCode == '54010' ||
                            this.OperatorCompareResults[i].ShortCode == '54021' ||
                            this.OperatorCompareResults[i].ShortCode == '54126'
                        ))

                    {
                        this.FOperatorCompareResults.push(this.OperatorCompareResults[i])
                    }
                    ++i;
                }

                var csmt_data_54126 = [];

                var csmt_data = [];

                var wind_data_54126 = [];
                var vdf_data_54126 = [];

                var csmt_data_54021 = [];
                var wind_data_54021 = [];
                var vdf_data_54021 = [];

                var csmt_data_54010 = [];
                var wind_data_54010 = [];
                var vdf_data_54010 = [];

                var csmt_data_54200 = [];
                var wind_data_54200 = [];
                var vdf_data_54200 = [];

                var graph_dates = []

                var sc = [];

                function onlyUnique(value, index, self) {
                    return self.indexOf(value) === index;
                }

                function getSC(item) {
                    sc.push(item.ShortCode)
                    graph_dates.push(item.TM)
                }

                this.FOperatorCompareResults.forEach(getSC)

                graph_dates = graph_dates.filter(onlyUnique);
                sc = sc.filter(onlyUnique);

                var tmp_object1 = {};
                var tmp_object2 = {};
                var tmp_object3 = {};

                sc.forEach(
                    element => {

                        tmp_object1 = {
                            "ShortCode": element,
                            "OperatorID": 1,
                            "data": []
                        }
                        tmp_object2 = {
                            "ShortCode": element,
                            "OperatorID": 2,
                            "data": []
                        }
                        tmp_object3 = {
                            "ShortCode": element,
                            "OperatorID": 3,
                            "data": []
                        }

                        csmt_data.push(tmp_object1)
                        csmt_data.push(tmp_object2)
                        csmt_data.push(tmp_object3)
                    }
                )

                function iterate(item) {

                    sc.push(item.ShortCode)

                    if (item.OperatorID == 1) {
                        if (item.ShortCode == '54126') {
                            vdf_data_54126.push(item.BillingsDelivered)
                        }
                        if (item.ShortCode == '54021') {
                            vdf_data_54021.push(item.BillingsDelivered)
                        }
                        if (item.ShortCode == '54010') {
                            vdf_data_54010.push(item.BillingsDelivered)
                        }
                        if (item.ShortCode == '54200') {
                            vdf_data_54200.push(item.BillingsDelivered)
                        }
                    }

                    if (item.OperatorID == 2) {
                        if (item.ShortCode == '54126') {
                            csmt_data_54126.push(item.BillingsDelivered)
                        }
                        if (item.ShortCode == '54021') {
                            csmt_data_54021.push(item.BillingsDelivered)
                        }
                        if (item.ShortCode == '54010') {
                            csmt_data_54010.push(item.BillingsDelivered)
                        }
                        if (item.ShortCode == '54200') {
                            csmt_data_54200.push(item.BillingsDelivered)
                        }
                    }

                    if (item.OperatorID == 3) {
                        if (item.ShortCode == '54126') {
                            wind_data_54126.push(item.BillingsDelivered)
                        }
                        if (item.ShortCode == '54021') {
                            wind_data_54021.push(item.BillingsDelivered)
                        }
                        if (item.ShortCode == '54010') {
                            wind_data_54010.push(item.BillingsDelivered)
                        }
                        if (item.ShortCode == '54200') {
                            wind_data_54200.push(item.BillingsDelivered)
                        }

                    }

                }

                this.FOperatorCompareResults.forEach(iterate)

                //unique_sc.forEach(iterate)
                //console.log('-----------------------------------')
                //console.log(sc)
                //console.log(csmt_data)
                //console.log('-----------------------------------')

                this.datacollection_54126 = {
                    labels: graph_dates,
                    datasets: [{
                            label: 'Cosmote',
                            borderColor: 'green',
                            pointBackgroundColor: 'green',
                            borderWidth: 1,
                            pointBorderColor: 'green',
                            data: csmt_data_54126
                        }, {
                            label: 'Wind',
                            borderColor: 'blue',
                            pointBackgroundColor: 'blue',
                            borderWidth: 1,
                            pointBorderColor: 'blue',
                            data: wind_data_54126,
                        }, {
                            label: 'Vodafone',
                            borderColor: 'red',
                            pointBackgroundColor: 'red',
                            borderWidth: 1,
                            pointBorderColor: 'red',
                            data: vdf_data_54126,
                        }

                    ],
                    options: this.graph_options
                }

                this.datacollection_54010 = {
                    labels: graph_dates,
                    datasets: [{
                            label: 'Cosmote',
                            borderColor: 'green',
                            pointBackgroundColor: 'green',
                            borderWidth: 1,
                            pointBorderColor: 'green',
                            data: csmt_data_54010
                        }, {
                            label: 'Wind',
                            borderColor: 'blue',
                            pointBackgroundColor: 'blue',
                            borderWidth: 1,
                            pointBorderColor: 'blue',
                            data: wind_data_54010,
                        }, {
                            label: 'Vodafone',
                            borderColor: 'red',
                            pointBackgroundColor: 'red',
                            borderWidth: 1,
                            pointBorderColor: 'red',
                            data: vdf_data_54010,
                        }

                    ],
                    options: this.graph_options
                }

                this.datacollection_54021 = {
                    labels: graph_dates,
                    datasets: [{
                            label: 'Cosmote',
                            borderColor: 'green',
                            pointBackgroundColor: 'green',
                            borderWidth: 1,
                            pointBorderColor: 'green',
                            data: csmt_data_54021
                        }, {
                            label: 'Wind',
                            borderColor: 'blue',
                            pointBackgroundColor: 'blue',
                            borderWidth: 1,
                            pointBorderColor: 'blue',
                            data: wind_data_54021,
                        }, {
                            label: 'Vodafone',
                            borderColor: 'red',
                            pointBackgroundColor: 'red',
                            borderWidth: 1,
                            pointBorderColor: 'red',
                            data: vdf_data_54021,
                        }

                    ],
                    options: this.graph_options
                }

                this.datacollection_54200 = {
                    labels: graph_dates,
                    datasets: [{
                            label: 'Cosmote',
                            borderColor: 'green',
                            pointBackgroundColor: 'green',
                            borderWidth: 1,
                            pointBorderColor: 'green',
                            data: csmt_data_54200
                        }, {
                            label: 'Wind',
                            borderColor: 'blue',
                            pointBackgroundColor: 'blue',
                            borderWidth: 1,
                            pointBorderColor: 'blue',
                            data: wind_data_54200,
                        }, {
                            label: 'Vodafone',
                            borderColor: 'red',
                            pointBackgroundColor: 'red',
                            borderWidth: 1,
                            pointBorderColor: 'red',
                            data: vdf_data_54200,
                        }

                    ],
                    options: this.graph_options
                }

                this.show = true
                if (wind_data_54126.length + csmt_data_54126.length + vdf_data_54126.length > 0) {
                    console.log('Sum 54126: ' + wind_data_54126.length + csmt_data_54126.length + vdf_data_54126.length);
                    this.showgraph_54126 = true
                }
                if (wind_data_54010.length + csmt_data_54010.length + vdf_data_54010.length > 0) {
                    console.log('Sum 54010: ' + wind_data_54010.length + csmt_data_54010.length + vdf_data_54010.length);
                    this.showgraph_54010 = true
                }
                if (wind_data_54200.length + csmt_data_54200.length + vdf_data_54200.length > 0) {
                    console.log('Sum 54200: ' + wind_data_54200.length + csmt_data_54200.length + vdf_data_54200.length);
                    this.showgraph_54200 = true
                }
                if (wind_data_54021.length + csmt_data_54021.length + vdf_data_54021.length > 0) {
                    console.log('Sum 54021: ' + wind_data_54021.length + csmt_data_54021.length + vdf_data_54021.length);
                    this.showgraph_54021 = true
                }
                this.loading = false

            })

        },
        ...mapActions({
            CompanyListResults: "mobivas/CompanyListResults",
            OperatorCompare: "mobivas/OperatorCompare",
            checkToken: "auth/checkToken"
        }),
        ...mapMutations({
            setAuthenticated: "auth/setAuthenticated",
            updateUser: "auth/updateUser",
            setCompanyID: "mobivas/setCompanyID",
            setDateFrom: "mobivas/setDateFrom",
            setDateTo: "mobivas/setDateTo",
        }),
        validateKey(key) {
            const isEnterKey = ["Enter"].indexOf(key.code) > -1;

            if (isEnterKey === true)
                this.handleUserHistory()
            else
                this.validateMsidn()
        },
    },
    computed: {
        dateRangeText() {
            return this.dates.join(' - ')
        },
    },
    mounted() {

        var currentDate = new Date().toJSON().slice(0, 10);
        this.setDateFrom(moment(currentDate, 'YYYY-MM-DDT21:00:00Z').add(-3, 'h').format('YYYY-MM-DDT21:00:00Z'))
        this.setDateTo(moment(currentDate, 'YYYY-MM-DDT20:59:59Z').format('YYYY-MM-DDT20:59:59Z'))

        this.checkToken()
            .then((response) => {

                if (response == '')
                    this.$router.push({
                        name: "Login"
                    })
            }),

            this.CompanyListResults().then((response) => {

                Object.keys(response).map((key) => {

                    this.CompanyItem.pkn_id = response. [key].pkn_id
                    this.CompanyItem.name = response. [key].s_description
                    if (response. [key].pkn_id > 1) {
                        this.CompanyList.push({
                            name: response. [key].s_description,
                            pkn_id: response. [key].pkn_id
                        })
                    }
                })
            })
    }
}
</script>

<style>
.results tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05);
}
</style>>

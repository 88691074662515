<template>
  <v-app>
    <div>
    <NavBar/>
    </div>

    <v-main class="mx-4">
       <router-view/>
    </v-main>
  </v-app>
</template>

<script>

import NavBar from '@/components/NavBar.vue'
import { mapGetters,mapActions } from 'vuex'


export default {
  name: 'App',
  components: {
    NavBar
  },  
  data: () => ({
    //
  }),
  methods: {

    ...mapActions ({
      login: "auth/login",
      checkToken: "auth/checkToken",
    }),
    validateKey(key) {
      const isEnterKey = ["Enter"].indexOf(key.code) > -1;
 
      if(isEnterKey === true)
        this.handleUserHistory()
      else
        this.validateMsidn()
    },
  },
  computed: {
    ...mapGetters ({
      getUser: "auth/getUser"
    }),
  }
};
</script>

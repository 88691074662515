<template>
<v-container fluid>
    <v-row align="center">

        <v-col xl="12" lg="12" sm="12" md="12" xs="12">
            <v-card class="elevation-1">
                <v-toolbar color="grey darken-1" flat dense>
                    <v-card-title class="text-body-1">Reports</v-card-title>
                </v-toolbar>
                <v-card-text>
                    <v-form>

                        <v-row align="center">
                            <v-col cols="6" sm="3">
                                <v-subheader v-text="'Company'"></v-subheader>
                            </v-col>
                            <v-col cols="12" sm="9">
                                <v-select v-model="selectedCompany" outlined dense small-chips :items="CompanyList" item-value="pkn_cid" item-text="name"></v-select>
                            </v-col>
                        </v-row>

                        <v-row align="center">
                            <v-col cols="6" sm="3">
                                <v-subheader v-text="'Service'"></v-subheader>
                            </v-col>
                            <v-col cols="12" sm="9">
                                <v-select placeholder="All Services" multiple outlined dense small-chips deletable-chips @change="getSelectedValueService" v-model="selectedService" :items="ListSubscriptionServiceData" item-value="pkn_sid" item-text="name"></v-select>
                            </v-col>
                        </v-row>

                        <v-row align="center">
                            <v-col cols="6" sm="3">
                                <v-subheader v-text="'Short Code'"></v-subheader>
                            </v-col>
                            <v-col cols="12" sm="9">
                                <v-select placeholder="All Short Codes" multiple outlined dense small-chips deletable-chips @change="getSelectedValueShortCode" :items="ListShortCodeData" item-value="pkn_scid" item-text="name"></v-select>
                            </v-col>
                        </v-row>

                        <v-row align="center">
                            <v-col cols="6" sm="3">
                                <v-subheader v-text="'Operator'"></v-subheader>
                            </v-col>
                            <v-col cols="12" sm="9">
                                <v-select placeholder="All Operators" multiple outlined dense small-chips deletable-chips @change="getSelectedValueOperator" :items="OperatorsList" item-value="pkn_oid" item-text="name"></v-select>
                            </v-col>
                        </v-row>

                        <v-row align="center">
                            <v-col cols="6" sm="3">
                                <v-subheader v-text="'Report Type'"></v-subheader>
                            </v-col>
                            <v-col cols="12" sm="9">
                                <v-select outlined dense v-model="defaultSelectedReport" small-chips @change="getSelectedValueReport" :items="ReportsList" item-value="pkn_rpid" item-text="name"></v-select>
                            </v-col>
                        </v-row>

                        <v-row align="center">
                            <v-col cols="6" sm="3">
                                <v-subheader v-text="'Report Interval'"></v-subheader>
                            </v-col>
                            <v-col cols="12" sm="9">
                                <v-select outlined dense v-model="defaultSelectedInterval" small-chips @change="getSelectedValueInterval" :items="IntervalList" item-value="pkn_intid" item-text="name"></v-select>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <div>
                                            <br>

                                            <v-md-date-range-picker @change="getSelectedDateRange" v-bind="attrs" v-on="on"></v-md-date-range-picker>

                                        </div>

                                    </template>

                                </v-menu>
                            </v-col>
                        </v-row>

                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" @click="fetchResults()">SHOW</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>

    <v-row align="center" v-show="ReportTypeRevenuesShow">
        <v-col cols="12" sm="12" md="12">
            <v-toolbar color="grey darken-1 elevation-1" flat>
                <v-toolbar-title class="text-body-1">Details</v-toolbar-title>

                <v-spacer></v-spacer>
                <v-btn v-show="export_json_show" class="mb-2">
                    <vue-excel-xlsx :data="AllResults" :columns="export_json_fields_Revenues" :sheetname="export_json_worksheet" :filename="export_json_filename">
                        EXPORT
                    </vue-excel-xlsx>
                </v-btn>
            </v-toolbar>

            <v-data-table :headers="headers_Revenues" :items="AllResults" class="elevation-1 results" :loading="loading" loading-text="Loading... Please wait" hide-default-footer disable-pagination>

                <template v-slot:items="props">
                    <td class="text-xs-right">{{ props.item.TM }}</td>
                    <td class="text-xs-right">{{ props.item.ClientPayoutAmount }}</td>
                    <td class="text-xs-right">{{ props.item.UserSpentAmount}}</td>
                </template>

                <template v-slot:item.ClientPayoutAmount="{ item }">
                    <span>{{ item.ClientPayoutAmount.toLocaleString("el-GR") }} &euro; </span>
                </template>
                <template v-slot:item.UserSpentAmount="{ item }">
                    <span>{{ item.UserSpentAmount.toLocaleString("el-GR") }} &euro; </span>
                </template>

                <template slot="body.append">
                    <tr class="black--text">
                        <th class="bold-footer">Totals</th>
                        <th class="bold-column">{{ sumField('UserSpentAmount').toFixed(2).toLocaleString("el-GR") }} &euro;</th>
                        <th class="bold-column">{{ sumField('ClientPayoutAmount').toFixed(2).toLocaleString("el-GR") }} &euro;</th>
                    </tr>
                </template>

            </v-data-table>
        </v-col>
    </v-row>

    <v-row align="center" v-show="ReportTypeBillingsShow">
        <v-col cols="12" sm="12" md="12">
            <v-toolbar color="grey darken-1 elevation-1" flat>
                <v-toolbar-title class="text-body-1">Details</v-toolbar-title>

                <v-spacer></v-spacer>
                <v-btn v-show="export_json_show" class="mb-2">
                    <vue-excel-xlsx :data="AllResults" :columns="export_json_fields_Billings" :sheetname="export_json_worksheet" :filename="export_json_filename">
                        EXPORT
                    </vue-excel-xlsx>
                </v-btn>
            </v-toolbar>

            <v-data-table :headers="headers_Billings" :items="AllResults" class="elevation-1 results" :loading="loading" loading-text="Loading... Please wait" hide-default-footer disable-pagination>

                <template slot="body.append">
                    <tr class="black--text">
                        <th class="bold-footer">Totals</th>
                        <th class="bold-column">{{ sumField('BillingsDelivered') }} </th>
                        <th class="bold-column">{{ sumField('BillingsRejected') }}</th>
                        <th class="bold-column">{{ sumField('BillingsPending') }}</th>
                        <th class="bold-column">{{ sumField('TotalBillingsSent') }} </th>
                    </tr>
                </template>

            </v-data-table>
        </v-col>
    </v-row>

    <v-row align="center" v-show="ReportTypeMTShow">
        <v-col cols="12" sm="12" md="12">
            <v-toolbar color="grey darken-1 elevation-1" flat>
                <v-toolbar-title class="text-body-1">Details</v-toolbar-title>

                <v-spacer></v-spacer>
                <v-btn v-show="export_json_show" class="mb-2">
                    <vue-excel-xlsx :data="AllResults" :columns="export_json_fields_MT" :sheetname="export_json_worksheet" :filename="export_json_filename">
                        EXPORT
                    </vue-excel-xlsx>
                </v-btn>
            </v-toolbar>

            <v-data-table :headers="headers_MT" :items="AllResults" class="elevation-1 results" :loading="loading" loading-text="Loading... Please wait" hide-default-footer disable-pagination>

                <template v-slot:items="props">
                    <td class="text-xs-right">{{ props.item.TM }}</td>
                    <td class="text-xs-right">{{ props.item.FreeMTDelivered }}</td>
                    <td class="text-xs-right">{{ props.item.FreeMTRejected}}</td>
                </template>

                <template v-slot:item.ClientPayoutAmount="{ item }">
                    <span>{{ item.FreeMTDelivered }}</span>
                </template>
                <template v-slot:item.UserSpentAmount="{ item }">
                    <span>{{ item.FreeMTRejected }} </span>
                </template>

                <template slot="body.append">
                    <tr class="black--text">
                        <th class="bold-footer">Totals</th>
                        <th class="bold-column">{{ sumField('FreeMTDelivered') }} </th>
                        <th class="bold-column">{{ sumField('FreeMTRejected') }} </th>
                    </tr>
                </template>

            </v-data-table>
        </v-col>
    </v-row>    

</v-container>
</template>

<script>
import Vue from 'vue';
import VMdDateRangePicker from "v-md-date-range-picker";
import moment from 'moment';
Vue.use(VMdDateRangePicker);

import {
    mapActions,
    mapMutations,
    mapGetters

} from 'vuex'

export default {
    data() {
        return {

            AllResults: [],

            CombinedUserSpent: [],
            CombinedClientPayout: [],
            CombinedBillingsDelivered: [],
            CombinedBillingsRejected: [],
            CombinedBillingsPending: [],
            CombinedTotalBillingsSent: [],
            FreeMTDelivered: [],
            FreeMTRejected: [],

            ReportTypeRevenuesShow: false,
            ReportTypeBillingsShow: false,
            ReportTypeMTShow: false,

            export_json_fields_Revenues: [{
                    label: 'Date',
                    field: 'TM'
                },
                {
                    label: 'User Spent',
                    field: 'UserSpentAmount'
                },
                {
                    label: 'Client Payout',
                    field: 'ClientPayoutAmount'
                }
            ],

            export_json_fields_MT: [{
                    label: 'Date',
                    field: 'TM'
                },
                {
                    label: 'Free MTs Delivered',
                    field: 'FreeMTDelivered'
                },
                {
                    label: 'Free MTs Rejected',
                    field: 'FreeMTRejected'
                }
            ],

            export_json_fields_Billings: [{
                    label: 'Date',
                    field: 'TM'
                },
                {
                    label: 'Billings Delivered',
                    field: 'BillingsDelivered'
                },
                {
                    label: 'Billings Rejected',
                    field: 'BillingsRejected'
                },
                {
                    label: 'Billings Pending',
                    field: 'BillingsPending'
                },
                {
                    label: 'Total Billings Sent',
                    field: 'TotalBillingsSent'
                },
            ],

            export_json_worksheet: "export",
            export_json_filename: "export",
            export_json_show: false,

            headers_MT: [{
                    text: 'Date',
                    align: 'left',
                    sortable: false,
                    value: 'TM'
                },
                {
                    text: 'Free MTs Delivered',
                    align: 'left',
                    sortable: false,
                    value: 'FreeMTDelivered'
                }
,
                {
                    text: 'Free MTs Rejected',
                    align: 'left',
                    sortable: false,
                    value: 'FreeMTRejected'
                }                
            ],

            headers_Revenues: [{
                    text: 'Date',
                    align: 'left',
                    sortable: false,
                    value: 'TM'
                },
                {
                    text: 'User Spent',
                    align: 'left',
                    sortable: false,
                    value: 'UserSpentAmount'
                },
                {
                    text: 'Client Payout',
                    align: 'left',
                    sortable: false,
                    value: 'ClientPayoutAmount'
                },
            ],

            headers_Billings: [{
                    text: 'Date',
                    align: 'left',
                    sortable: false,
                    value: 'TM'
                },
                {
                    text: 'Billings Delivered',
                    align: 'left',
                    sortable: false,
                    value: 'BillingsDelivered'
                },
                {
                    text: 'Billings Rejected',
                    align: 'left',
                    sortable: false,
                    value: 'BillingsRejected'
                },
                {
                    text: 'Billings Pending',
                    align: 'left',
                    sortable: false,
                    value: 'BillingsPending'
                },
                {
                    text: 'Total Billings Sent',
                    align: 'left',
                    sortable: false,
                    value: 'TotalBillingsSent'
                },
            ],

            Company: this.getUserCompany(),

            CompanyList: [{
                name: this.getUserCompanyDesc(),
                pkn_cid: this.getUserCompany()
            }],
            selectedCompany: this.getUserCompany(),

            ListSubscriptionServiceData: [],

            ListShortCodeData: [],
            selectedShortCode: null,

            OperatorsList: [{
                    name: "Cosmote",
                    pkn_oid: 2
                },
                {
                    name: "Vodafone",
                    pkn_oid: 1
                },
                {
                    name: "Wind",
                    pkn_oid: 3
                },
            ],
            selectedOperator: null,

            ReportsList: [{
                    name: "Revenues |  User Spent / Client Payout",
                    pkn_rpid: 1
                },
                {
                    name: "Billings | Delivered/Rejected/Pending/Sent",
                    pkn_rpid: 2
                },
                {
                    name: "Free MT | MTs Delivered / MTs Rejected",
                    pkn_rpid: 3
                },
                {
                    name: "Error",
                    pkn_rpid: 3
                },
            ],
            defaultSelectedReport: {
                name: "Revenues",
                pkn_rpid: 1
            },
            IntervalList: [{
                    name: "Hourly",
                    pkn_intid: 1
                },
                {
                    name: "Daily",
                    pkn_intid: 2
                },
                {
                    name: "Monthly",
                    pkn_intid: 3
                },
            ],
            defaultSelectedInterval: {
                name: "Daily",
                pkn_intid: 2
            },
            value: null,
            date: "",
            dates: [moment().format('YYYY-MM-DD')],
            dateFrom: "",
            dateTo: "",
            payload: {},
            loading: false,
            menu: false,
            SubscriptionServicesDelimited: '',
            ShortCodesDelimited: '',
            ShortCodesDelimited_tmp: '',
            OperatorsDelimited: '1;2;3',
            ReportType: 1,
            ReportIntervalType: 2,

        }
    },
    methods: {

        sumField(key) {
            let total = 0
            const sum = this.AllResults.reduce((acc, cur) => {
                return (total += +cur[key])
            }, 0)
            return sum
        },

        logout() {
            this.setAuthenticated(false)
            this.updateField('', 'token')
            this.updateField('', 'tokentimestamp')
            this.updateField('', 'username')
            this.updateField('', 'password')
        },

        getSelectedValueReport(pkn_rpid) {
            this.ReportType = pkn_rpid;
            this.setSelectedReportType(this.ReportType);

        },
        getSelectedValueInterval(pkn_intid) {
            this.ReportIntervalType = pkn_intid;
            this.setSelectedReportInterval(this.ReportIntervalType);
        },
        getSelectedValueShortCode(pkn_scid) {
            if (pkn_scid.length == 0) {
                this.ShortCodesDelimited = this.ShortCodesDelimited_tmp
            } else {
                this.ShortCodesDelimited = pkn_scid.join(';')
            }
            this.setShortCodesDelimited(this.ShortCodesDelimited)
        },
        getSelectedValueOperator(pkn_oid) {
            if (pkn_oid.length == 0) {
                this.OperatorsDelimited = '1;2;3'
            } else {
                this.OperatorsDelimited = pkn_oid.join(';');
            }
            this.setOperatorsDelimited(this.OperatorsDelimited);

        },
        getSelectedValueService(pkn_sid) {
            if (pkn_sid.length == 0) {
                this.SubscriptionServicesDelimited = this.SubscriptionServicesDelimited_tmp
            } else {
                this.SubscriptionServicesDelimited = pkn_sid.join(';');
            }
            this.setSubscriptionServicesDelimited(this.SubscriptionServicesDelimited)
        },

        getSelectedDateRange(values) {

            this.setDateFrom(moment(values[0], 'YYYY-MM-DDT21:00:00Z').add(-3, 'h').format('YYYY-MM-DDT21:00:00Z'))
            this.setDateTo(moment(values[1], 'YYYY-MM-DDT20:59:59Z').format('YYYY-MM-DDT20:59:59Z'))

        },

        fetchResults() {

            console.log('Fetching')
            this.ReportTypeRevenuesShow = false
            this.ReportTypeBillingsShow = false
            this.ReportTypeMTShow = false

            this.AllResults = []
            this.AllResults1 = []
            this.AllResults2 = []
            this.CombinedUserSpent = []
            this.CombinedClientPayout = []
            this.FreeMTRejected = []
            this.FreeMTDelivered = []

            this.CombinedBillingsDelivered = []
            this.CombinedBillingsRejected = []
            this.CombinedBillingsPending = []
            this.CombinedTotalBillingsSent = []

            this.export_json_show = false
            this.loading = true

            console.log('-------------------------------------------')
            console.log('Company: ' + this.getUserCompany())
            console.log('Service: ' + this.getSubscriptionServicesDelimited())
            console.log('ShortCode: ' + this.getShortCodesDelimited())
            console.log('Operator: ' + this.getOperatorsDelimited())
            console.log('Report Type: ' + this.getSelectedReportType())
            console.log('Interval: ' + this.getSelectedReportInterval())
            console.log('-------------------------------------------')

            this.CombinedReporting().then((response) => {

                console.log('----------Result-----------------')
                console.log('Get combined')

                if (this.getSelectedReportType() == 1) {

                    this.ReportTypeRevenuesShow = true;

                    Object.keys(response).map((key) => {

                        if (response. [key].Metric == "ClientPayout" && response. [key].TM != 'Total') {
                            this.CombinedClientPayout.push({
                                "TM": response. [key].TM.substring(0, (this.getSelectedReportInterval() == 1 ? 13 : 10)),
                                "ClientPayoutAmount": response. [key].Amount.toFixed(2)
                            })
                        }

                        if (response. [key].Metric == "UserSpent" && response. [key].TM != 'Total') {

                            this.CombinedUserSpent.push({
                                "TM": response. [key].TM.substring(0, (this.getSelectedReportInterval() == 1 ? 13 : 10)),
                                "UserSpentAmount": response. [key].Amount.toFixed(2)
                            })
                        }

                    })

                    for (let i = 0; i < this.CombinedClientPayout.length; i++) {
                        this.AllResults.push({
                            ...this.CombinedClientPayout[i],
                            ...(this.CombinedUserSpent.find((itmInner) => itmInner.TM === this.CombinedClientPayout[i].TM))
                        });

                    }
                }

                if (this.getSelectedReportType() == 2) {

                    this.ReportTypeBillingsShow = true;

                    Object.keys(response).map((key) => {

                        if (response. [key].Metric == "BillingsDelivered" && response. [key].TM != 'Total') {
                            this.CombinedBillingsDelivered.push({
                                "TM": response. [key].TM.substring(0, (this.getSelectedReportInterval() == 1 ? 13 : 10)),
                                "BillingsDelivered": response. [key].Amount
                            })
                        }
                        if (response. [key].Metric == "BillingsRejected" && response. [key].TM != 'Total') {
                            this.CombinedBillingsRejected.push({
                                "TM": response. [key].TM.substring(0, (this.getSelectedReportInterval() == 1 ? 13 : 10)),
                                "BillingsRejected": response. [key].Amount
                            })
                        }
                        if (response. [key].Metric == "BillingsPending" && response. [key].TM != 'Total') {
                            this.CombinedBillingsPending.push({
                                "TM": response. [key].TM.substring(0, (this.getSelectedReportInterval() == 1 ? 13 : 10)),
                                "BillingsPending": response. [key].Amount
                            })
                        }
                        if (response. [key].Metric == "TotalBillingsSent" && response. [key].TM != 'Total') {
                            this.CombinedTotalBillingsSent.push({
                                "TM": response. [key].TM.substring(0, (this.getSelectedReportInterval() == 1 ? 13 : 10)),
                                "TotalBillingsSent": response. [key].Amount
                            })
                        }
                    })

                    console.log(this.CombinedTotalBillingsSent)

                    let AllResults1 = []
                    let AllResults2 = []

                    for (let i = 0; i < this.CombinedBillingsDelivered.length; i++) {
                        AllResults1.push({
                            ...this.CombinedBillingsDelivered[i],
                            ...(this.CombinedBillingsRejected.find((itmInner) => itmInner.TM === this.CombinedBillingsDelivered[i].TM))
                        });
                    }

                    for (let i = 0; i < this.CombinedBillingsPending.length; i++) {
                        AllResults2.push({
                            ...this.CombinedBillingsPending[i],
                            ...(this.CombinedTotalBillingsSent.find((itmInner) => itmInner.TM === this.CombinedBillingsPending[i].TM))
                        });
                    }

                    for (let i = 0; i < AllResults1.length; i++) {
                        this.AllResults.push({
                            ...AllResults1[i],
                            ...(AllResults2.find((itmInner) => itmInner.TM === AllResults1[i].TM))
                        });
                    }

                    console.log(AllResults1);
                    console.log(AllResults2);

                }

                if (this.getSelectedReportType() == 3) {

                    this.ReportTypeMTShow = true;

                    Object.keys(response).map((key) => {

                        if (response. [key].Metric == "FreeMTDelivered" && response. [key].TM != 'Total') {
                            this.FreeMTDelivered.push({
                                "TM": response. [key].TM.substring(0, (this.getSelectedReportInterval() == 1 ? 13 : 10)),
                                "FreeMTDelivered": response. [key].Amount
                            })
                        }

                        if (response. [key].Metric == "FreeMTRejected" && response. [key].TM != 'Total') {

                            this.FreeMTRejected.push({
                                "TM": response. [key].TM.substring(0, (this.getSelectedReportInterval() == 1 ? 13 : 10)),
                                "FreeMTRejected": response. [key].Amount
                            })
                        }

                    })

                    for (let i = 0; i < this.FreeMTDelivered.length; i++) {
                        this.AllResults.push({
                            ...this.FreeMTDelivered[i],
                            ...(this.FreeMTRejected.find((itmInner) => itmInner.TM === this.FreeMTDelivered[i].TM))
                        });

                    }
                }

                this.loading = false
                this.export_json_show = true
                console.log('--------End Result---------------')
            })

        },

        ...mapGetters({
            usr: "auth/getUser",
            getUserCompany: "auth/getUserCompany",
            getUserCompanyDesc: "auth/getUserCompanyDesc",
            getDateFrom: "mobivas/getDateFrom",
            getDateTo: "mobivas/getDateTo",
            getSelectedReportInterval: "mobivas/getSelectedReportInterval",
            getSelectedReportType: "mobivas/getSelectedReportType",
            getOperatorsDelimited: "mobivas/getOperatorsDelimited",
            getShortCodesDelimited: "mobivas/getShortCodesDelimited",
            getSubscriptionServicesDelimited: "mobivas/getSubscriptionServicesDelimited",
        }),
        ...mapActions({
            checkToken: "auth/checkToken",
            ListSubscriptionService: "mobivas/ListSubscriptionService",
            CombinedReporting: "mobivas/CombinedReporting",
            ListShortCode: "mobivas/ListShortCode",
        }),

        ...mapMutations({
            setAuthenticated: "auth/setAuthenticated",
            updateUser: "auth/updateUser",
            setDateFrom: "mobivas/setDateFrom",
            setDateTo: "mobivas/setDateTo",
            setMetricIDsDelimited: "mobivas/setMetricIDsDelimited",
            setOperatorsDelimited: "mobivas/setOperatorsDelimited",
            setShortCodesDelimited: "mobivas/setShortCodesDelimited",
            setSelectedReportType: "mobivas/setSelectedReportType",
            setSelectedReportInterval: "mobivas/setSelectedReportInterval",
            setSubscriptionServicesDelimited: "mobivas/setSubscriptionServicesDelimited"
        }),

        validateKey(key) {
            const isEnterKey = ["Enter"].indexOf(key.code) > -1;

            if (isEnterKey === true)
                this.handleUserHistory()
            else
                this.validateMsidn()
        },
    },
    computed: {
        dateRangeText() {
            return this.dates.join(' - ')
        },
    },
    mounted() {

        this.ListSubscriptionService().then((response) => {

            var SubscriptionServicesDelimited = ''
            Object.keys(response).map((key) => {
                SubscriptionServicesDelimited = SubscriptionServicesDelimited + response. [key].pkn_id + ';'
                this.ListSubscriptionServiceData.push({
                    pkn_sid: response. [key].pkn_id,
                    name: response. [key].s_description

                })
            })
            this.SubscriptionServicesDelimited_tmp = SubscriptionServicesDelimited.slice(0, -1)
            this.setSubscriptionServicesDelimited(SubscriptionServicesDelimited.slice(0, -1))

        })

        this.ListShortCode().then((response) => {
            var ShortCodesDelimited = ''
            Object.keys(response).map((key) => {
                ShortCodesDelimited = ShortCodesDelimited + response. [key].pks_shortCode + ';'
                this.ListShortCodeData.push({
                    name: response. [key].pks_shortCode,
                    pkn_scid: response. [key].pks_shortCode
                })
            })
            this.ShortCodesDelimited_tmp = ShortCodesDelimited.slice(0, -1)
            this.setShortCodesDelimited(ShortCodesDelimited.slice(0, -1))
        })

        var currentDate = new Date().toJSON().slice(0, 10);
        this.setDateFrom(moment(currentDate, 'YYYY-MM-DDT21:00:00Z').add(-3, 'h').format('YYYY-MM-DDT21:00:00Z'))
        this.setDateTo(moment(currentDate, 'YYYY-MM-DDT20:59:59Z').format('YYYY-MM-DDT20:59:59Z'))

        this.checkToken()
            .then((response) => {

                if (response == '')
                    this.$router.push({
                        name: "Login"
                    })
            })

    }
}
</script>

<style>
.results tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05);

}

.v-text-field__details {
    display: none;
}
</style>>

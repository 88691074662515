<template>
<v-container fluid grid-list-md>
    <v-row align="center" style="padding-top:20px;">
        <v-col cols="12" sm="12" md="12">
            <v-toolbar class="grey darken-1" flat dense>
                <v-toolbar-title class="text-body-1"> Banned Subscribers</v-toolbar-title>
            </v-toolbar>

            <v-data-table :search="search" :v-show="false" :headers="headers" :items="BanListData" class="elevation-1 results" :loading="loading" loading-text="Loading... Please wait">
                <template v-slot:top>
                    <v-text-field v-model="search" label="Search" class="mx-4"></v-text-field>
                </template>

                <template v-slot:item.d_dateTime="{ item }">
                    {{ item.d_dateTime.replace("T"," ").substring(0, 19) }}
                </template>

            </v-data-table>
        </v-col>
    </v-row>
</v-container>
</template>

<script>

import {
    mapGetters,
    mapActions,
    mapMutations
} from 'vuex'

export default {
    data() {

        return {
            search:'',
            isLoading: true,
            fullPage: true,

            headers: [{
                    text: 'Msisdn',
                    align: 'left',
                    sortable: false,
                    value: 'pfks_phoneNumber'
                },
                {
                    text: 'Reason',
                    align: 'left',
                    sortable: false,
                    value: 's_description'
                },
                {
                    text: 'Date',
                    align: 'left',
                    sortable: false,
                    value: 'd_dateTime'
                }
            ],

            BanListData: [],

            loading: false,
            menu: false
        }
    },
    methods: {

        ...mapActions({
            BanList: "mobivas/BanList",
            checkToken: "auth/checkToken"
        }),
        ...mapMutations({
            setAuthenticated: "auth/setAuthenticated",
            updateUser: "auth/updateUser",
        }),

        showBanList() {

            this.BanList().then((response) => {
                this.BanListData = response
                this.loading = false
            })

        },

        validateKey(key) {
            const isEnterKey = ["Enter"].indexOf(key.code) > -1;

            if (isEnterKey === true)
                this.handleUserHistory()
            else
                this.validateMsidn()
        },
        logout() {
            this.setAuthenticated(false)
            this.updateField('', 'token')
            this.updateField('', 'tokentimestamp')
            this.updateField('', 'username')
            this.updateField('', 'password')
        },
    },
    computed: {
        ...mapGetters({
            getUser: "auth/getUser"
        }),
    },
    mounted() {
        this.drawer = true,
            this.checkToken()
            .then((response) => {

                if (response == '') {
                    this.logout()
                    this.$router.push({
                        name: "Login"
                    })
                }
            }),
            this.showBanList()
    }
}
</script>

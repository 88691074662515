import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from "../store"
import AggregatedBillings from '../views/AggregatedBillings.vue'
import AggregatedBillingsUser from '../views/AggregatedBillings-user.vue'
import hlrLookups from '../views/HLRLookup.vue'
import Login from '../views/Login.vue'
import Dashboard from '../views/Dashboard.vue'
import DashboardUser from '../views/Dashboard-user.vue'
import AccountDetails from '../views/AccountDetails.vue'
import AccountDetailsUser from '../views/AccountDetails-user.vue'
import BillingDiscrepancies from '../views/BillingDiscrepancies.vue'
import BannedSubscribers from '../views/BannedSubscribers.vue'
import StopMessages from '../views/MoMessages'
import OperatorCompare from '../views/OperatorCompare'
import Unsubscribe from '../views/UnsubscribeTool.vue'
import ReportsUser from '../views/Reports-user.vue'

Vue.use(VueRouter)

function beforeEach(to, from, next) {
    const isAuth = store.getters["auth/isAuthenticated"];
    const isLoginPages = ["/login"].includes(to.fullPath);

    if (!isAuth && !isLoginPages) {
        next("/login");
    } else if (isAuth && isLoginPages) {
        next("/");
    } else {
        next();
    }
}


const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/aggregatedbillings',
        name: 'AggregatedBillings',
        component: AggregatedBillings
    },
    {
        path: '/aggregatedbillings-user',
        name: 'AggregatedBillingsUser',
        component: AggregatedBillingsUser
    },
    {
        path: '/hlrLookups',
        name: 'hlrLookups',
        component: hlrLookups
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard
    },
    {
        path: '/dashboard-user',
        name: 'DashboardUser',
        component: DashboardUser
    },
    {
        path: '/account-details',
        name: 'AccountDetails',
        component: AccountDetails
    },
    {
        path: '/account-details-user',
        name: 'AccountDetailsUser',
        component: AccountDetailsUser
    },
    {
        path: '/billing-discrepancies',
        name: 'BillingDiscrepancies',
        component: BillingDiscrepancies
    },
    {
        path: '/banned-subscribers',
        name: 'BannedSubscribers',
        component: BannedSubscribers
    },
    {
        path: '/mo-messages',
        name: 'MoMessages',
        component: StopMessages
    },
    {
        path: '/operator-compare',
        name: 'OperatorCompare',
        component: OperatorCompare
    },
    {
        path: '/unsubscribe',
        name: 'Unsubscribe',
        component: Unsubscribe
    },
    {
        path: '/reports-user',
        name: 'ReportsUser',
        component: ReportsUser
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(beforeEach)

export default router
export default {
  namespaced: true,
  strict : true,

  state: {
    dark: true
  },
  getters: {
    getDark: (state) => {
        return state.dark
    },
  },
  mutations: {
    setDark: (state, payload) => {
      //console.log("setDark " + payload)
      state.dark = payload
    }
  },

}